/*---------------pseudo element default styles---------------*/
/*---------------------pseudo full size---------------------*/
/*--------------------proportional ratio--------------------*/
/*--------------------placeholder styles--------------------*/
/*-------------------------clearfix-------------------------*/
/*------------------------transition------------------------*/
/*--------------------background image--------------------*/
/*--------------------absolute alignment--------------------*/
/*------------------------animation------------------------*/
/*------------------make triangle------------------*/
/*---------------------------fonts---------------------------*/
/*-----------------------for browsers-----------------------*/
/*-----------------------map get recursive-----------------------*/
/*-----------------------font size-----------------------*/
/* Colors variables start */
/* Colors variables end */
/* Fonts variables start */
/* Fonts variables end */
/* Reboot variables start */
/* Grid variables start */
/* Grid variables end */
/* Responsive font-size start */
/* Responsive font-size end */
/*Common variables start */
/*Common variables end */
/*---------------pseudo element default styles---------------*/
/*---------------------pseudo full size---------------------*/
/*--------------------proportional ratio--------------------*/
/*--------------------placeholder styles--------------------*/
/*-------------------------clearfix-------------------------*/
/*------------------------transition------------------------*/
/*--------------------background image--------------------*/
/*--------------------absolute alignment--------------------*/
/*------------------------animation------------------------*/
/*------------------make triangle------------------*/
/*---------------------------fonts---------------------------*/
/*-----------------------for browsers-----------------------*/
/*-----------------------map get recursive-----------------------*/
/*-----------------------font size-----------------------*/
@font-face { font-family: "OpenSansRegular"; src: url("../fonts/OpenSans/OpenSansRegular/OpenSansRegular.woff2") format("woff2"), url("../fonts/OpenSans/OpenSansRegular/OpenSansRegular.woff") format("woff"), url("../fonts/OpenSans/OpenSansRegular/OpenSansRegular.ttf") format("truetype"); font-weight: normal; font-style: normal; }

@font-face { font-family: "OpenSansLight"; src: url("../fonts/OpenSans/OpenSansLight/OpenSansLight.woff2") format("woff2"), url("../fonts/OpenSans/OpenSansLight/OpenSansLight.woff") format("woff"), url("../fonts/OpenSans/OpenSansLight/OpenSansLight.ttf") format("truetype"); font-weight: normal; font-style: normal; }

@font-face { font-family: "OpenSansSemiBold"; src: url("../fonts/OpenSans/OpenSansSemiBold/OpenSansSemiBold.woff2") format("woff2"), url("../fonts/OpenSans/OpenSansSemiBold/OpenSansSemiBold.woff") format("woff"), url("../fonts/OpenSans/OpenSansSemiBold/OpenSansSemiBold.ttf") format("truetype"); font-weight: normal; font-style: normal; }

@font-face { font-family: "OpenSansBold"; src: url("../fonts/OpenSans/OpenSansBold/OpenSansBold.woff2") format("woff2"), url("../fonts/OpenSans/OpenSansBold/OpenSansBold.woff") format("woff"), url("../fonts/OpenSans/OpenSansBold/OpenSansBold.ttf") format("truetype"); font-weight: normal; font-style: normal; }

@font-face { font-family: "OpenSansItalic"; src: url("../fonts/OpenSans/OpenSansItalic/OpenSansItalic.woff2") format("woff2"), url("../fonts/OpenSans/OpenSansItalic/OpenSansItalic.woff") format("woff"), url("../fonts/OpenSans/OpenSansItalic/OpenSansItalic.ttf") format("truetype"); font-weight: normal; font-style: normal; }

@font-face { font-family: "RubikRegular"; src: url("../fonts/Rubik/RubikRegular/RubikRegular.woff2") format("woff2"), url("../fonts/Rubik/RubikRegular/RubikRegular.woff") format("woff"), url("../fonts/Rubik/RubikRegular/RubikRegular.ttf") format("truetype"); font-weight: normal; font-style: normal; }

@font-face { font-family: "RubikMedium"; src: url("../fonts/Rubik/RubikMedium/RubikMedium.woff2") format("woff2"), url("../fonts/Rubik/RubikMedium/RubikMedium.woff") format("woff"), url("../fonts/Rubik/RubikMedium/RubikMedium.ttf") format("truetype"); font-weight: normal; font-style: normal; }

@font-face { font-family: "RubikLight"; src: url("../fonts/Rubik/RubikLight/RubikLight.woff2") format("woff2"), url("../fonts/Rubik/RubikLight/RubikLight.woff") format("woff"), url("../fonts/Rubik/RubikLight/RubikLight.ttf") format("truetype"); font-weight: normal; font-style: normal; }

*, *::before, *::after { box-sizing: border-box; }

html { font-family: sans-serif; line-height: 1.15; -webkit-text-size-adjust: 100%; -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section { display: block; }

body { margin: 0; font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Helvetica Neue", Arial, sans-serif; font-size: 1rem; font-weight: normal; line-height: 1.35; color: #101010; text-align: left; background-color: #eeeeee; }

[tabindex="-1"]:focus:not(:focus-visible) { outline: 0 !important; }

hr { color: #000; background-color: #000; box-sizing: content-box; border: 0 none; height: 1px; overflow: visible; clear: both; }

h1, h2, h3, h4, h5, h6 { margin-top: 0; margin-bottom: 0.3125rem; }

p { margin-top: 0; margin-bottom: 1rem; }

address { margin-bottom: 0; font-style: normal; line-height: inherit; }

ol, ul, dl { margin-top: 0; margin-bottom: 1rem; }

ol ol, ul ul, ol ul, ul ol { margin-bottom: 0; }

dt { font-weight: 700; }

dd { margin-bottom: .5rem; margin-left: 0; }

blockquote { margin: 0; }

b, strong { font-weight: bolder; }

small { font-size: 80%; }

sub, sup { position: relative; font-size: 75%; line-height: 0; vertical-align: baseline; }

sub { bottom: -.25em; }

sup { top: -.5em; }

a { color: #007bff; text-decoration: none; background-color: transparent; }

a:hover { color: #0056b3; text-decoration: none; }

a:not([href]):not([class]) { color: inherit; text-decoration: none; }

a:not([href]):not([class]):hover { color: inherit; text-decoration: none; }

pre, code, kbd, samp { font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; font-size: 1em; }

pre { margin-top: 0; margin-bottom: 0.625rem; overflow: auto; -ms-overflow-style: scrollbar; }

figure { margin: 0 0 0.625rem; }

img { vertical-align: middle; border-style: none; }

svg { overflow: hidden; vertical-align: middle; }

table { border-collapse: collapse; }

caption { padding-top: 0.75rem; padding-bottom: 0.75rem; color: #6c757d; text-align: left; caption-side: bottom; }

th { text-align: inherit; }

label { display: inline-block; margin-bottom: 0.5rem; }

button { border-radius: 0; }

button:focus { outline: none; }

input, button, select, optgroup, textarea { font-size: inherit; margin: 0; font-family: inherit; line-height: inherit; }

button, input { overflow: visible; }

button, select { text-transform: none; }

[role="button"] { cursor: pointer; }

select { word-wrap: normal; }

button, [type="button"], [type="reset"], [type="submit"] { -webkit-appearance: button; }

button:not(:disabled), [type="button"]:not(:disabled), [type="reset"]:not(:disabled), [type="submit"]:not(:disabled) { cursor: pointer; }

button::-moz-focus-inner, [type="button"]::-moz-focus-inner, [type="reset"]::-moz-focus-inner, [type="submit"]::-moz-focus-inner { padding: 0; border-style: none; }

input[type="radio"], input[type="checkbox"] { box-sizing: border-box; padding: 0; }

textarea { overflow: auto; resize: none; }

fieldset { min-width: 0; padding: 0; margin: 0; border: 0; }

legend { display: block; width: 100%; max-width: 100%; padding: 0; margin-bottom: .5rem; line-height: inherit; color: inherit; white-space: normal; }

progress { vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button, [type="number"]::-webkit-outer-spin-button { height: auto; }

[type="search"] { outline-offset: -2px; -webkit-appearance: none; }

[type="search"]::-webkit-search-decoration { -webkit-appearance: none; }

::-webkit-file-upload-button { font: inherit; -webkit-appearance: button; }

output { display: inline-block; }

summary { display: list-item; cursor: pointer; }

template { display: none; }

[hidden] { display: none !important; }

.text-left { text-align: left !important; }

.text-right { text-align: right !important; }

.text-center { text-align: center !important; }

@media (min-width: 576px) { .text-sm-left { text-align: left !important; }
  .text-sm-right { text-align: right !important; }
  .text-sm-center { text-align: center !important; } }

@media (min-width: 768px) { .text-md-left { text-align: left !important; }
  .text-md-right { text-align: right !important; }
  .text-md-center { text-align: center !important; } }

@media (min-width: 992px) { .text-lg-left { text-align: left !important; }
  .text-lg-right { text-align: right !important; }
  .text-lg-center { text-align: center !important; } }

@media (min-width: 1200px) { .text-xl-left { text-align: left !important; }
  .text-xl-right { text-align: right !important; }
  .text-xl-center { text-align: center !important; } }

@media (min-width: 1400px) { .text-vl-left { text-align: left !important; }
  .text-vl-right { text-align: right !important; }
  .text-vl-center { text-align: center !important; } }

@media (min-width: 1600px) { .text-sl-left { text-align: left !important; }
  .text-sl-right { text-align: right !important; }
  .text-sl-center { text-align: center !important; } }

.text-lowercase { text-transform: lowercase !important; }

.text-uppercase { text-transform: uppercase !important; }

.text-capitalize { text-transform: capitalize !important; }

.m-0 { margin: 0 !important; }

.mt-0, .my-0 { margin-top: 0 !important; }

.mr-0, .mx-0 { margin-right: 0 !important; }

.mb-0, .my-0 { margin-bottom: 0 !important; }

.ml-0, .mx-0 { margin-left: 0 !important; }

.m-1 { margin: 0.15625rem !important; }

.mt-1, .my-1 { margin-top: 0.15625rem !important; }

.mr-1, .mx-1 { margin-right: 0.15625rem !important; }

.mb-1, .my-1 { margin-bottom: 0.15625rem !important; }

.ml-1, .mx-1 { margin-left: 0.15625rem !important; }

.m-2 { margin: 0.3125rem !important; }

.mt-2, .my-2 { margin-top: 0.3125rem !important; }

.mr-2, .mx-2 { margin-right: 0.3125rem !important; }

.mb-2, .my-2 { margin-bottom: 0.3125rem !important; }

.ml-2, .mx-2 { margin-left: 0.3125rem !important; }

.m-3 { margin: 0.625rem !important; }

.mt-3, .my-3 { margin-top: 0.625rem !important; }

.mr-3, .mx-3 { margin-right: 0.625rem !important; }

.mb-3, .my-3 { margin-bottom: 0.625rem !important; }

.ml-3, .mx-3 { margin-left: 0.625rem !important; }

.m-4 { margin: 0.9375rem !important; }

.mt-4, .my-4 { margin-top: 0.9375rem !important; }

.mr-4, .mx-4 { margin-right: 0.9375rem !important; }

.mb-4, .my-4 { margin-bottom: 0.9375rem !important; }

.ml-4, .mx-4 { margin-left: 0.9375rem !important; }

.m-5 { margin: 1.875rem !important; }

.mt-5, .my-5 { margin-top: 1.875rem !important; }

.mr-5, .mx-5 { margin-right: 1.875rem !important; }

.mb-5, .my-5 { margin-bottom: 1.875rem !important; }

.ml-5, .mx-5 { margin-left: 1.875rem !important; }

.p-0 { padding: 0 !important; }

.pt-0, .py-0 { padding-top: 0 !important; }

.pr-0, .px-0 { padding-right: 0 !important; }

.pb-0, .py-0 { padding-bottom: 0 !important; }

.pl-0, .px-0 { padding-left: 0 !important; }

.p-1 { padding: 0.15625rem !important; }

.pt-1, .py-1 { padding-top: 0.15625rem !important; }

.pr-1, .px-1 { padding-right: 0.15625rem !important; }

.pb-1, .py-1 { padding-bottom: 0.15625rem !important; }

.pl-1, .px-1 { padding-left: 0.15625rem !important; }

.p-2 { padding: 0.3125rem !important; }

.pt-2, .py-2 { padding-top: 0.3125rem !important; }

.pr-2, .px-2 { padding-right: 0.3125rem !important; }

.pb-2, .py-2 { padding-bottom: 0.3125rem !important; }

.pl-2, .px-2 { padding-left: 0.3125rem !important; }

.p-3 { padding: 0.625rem !important; }

.pt-3, .py-3 { padding-top: 0.625rem !important; }

.pr-3, .px-3 { padding-right: 0.625rem !important; }

.pb-3, .py-3 { padding-bottom: 0.625rem !important; }

.pl-3, .px-3 { padding-left: 0.625rem !important; }

.p-4 { padding: 0.9375rem !important; }

.pt-4, .py-4 { padding-top: 0.9375rem !important; }

.pr-4, .px-4 { padding-right: 0.9375rem !important; }

.pb-4, .py-4 { padding-bottom: 0.9375rem !important; }

.pl-4, .px-4 { padding-left: 0.9375rem !important; }

.p-5 { padding: 1.875rem !important; }

.pt-5, .py-5 { padding-top: 1.875rem !important; }

.pr-5, .px-5 { padding-right: 1.875rem !important; }

.pb-5, .py-5 { padding-bottom: 1.875rem !important; }

.pl-5, .px-5 { padding-left: 1.875rem !important; }

.m-auto { margin: auto !important; }

.mt-auto, .my-auto { margin-top: auto !important; }

.mr-auto, .mx-auto { margin-right: auto !important; }

.mb-auto, .my-auto { margin-bottom: auto !important; }

.ml-auto, .mx-auto { margin-left: auto !important; }

@media (min-width: 576px) { .m-sm-0 { margin: 0 !important; }
  .mt-sm-0, .my-sm-0 { margin-top: 0 !important; }
  .mr-sm-0, .mx-sm-0 { margin-right: 0 !important; }
  .mb-sm-0, .my-sm-0 { margin-bottom: 0 !important; }
  .ml-sm-0, .mx-sm-0 { margin-left: 0 !important; }
  .m-sm-1 { margin: 0.15625rem !important; }
  .mt-sm-1, .my-sm-1 { margin-top: 0.15625rem !important; }
  .mr-sm-1, .mx-sm-1 { margin-right: 0.15625rem !important; }
  .mb-sm-1, .my-sm-1 { margin-bottom: 0.15625rem !important; }
  .ml-sm-1, .mx-sm-1 { margin-left: 0.15625rem !important; }
  .m-sm-2 { margin: 0.3125rem !important; }
  .mt-sm-2, .my-sm-2 { margin-top: 0.3125rem !important; }
  .mr-sm-2, .mx-sm-2 { margin-right: 0.3125rem !important; }
  .mb-sm-2, .my-sm-2 { margin-bottom: 0.3125rem !important; }
  .ml-sm-2, .mx-sm-2 { margin-left: 0.3125rem !important; }
  .m-sm-3 { margin: 0.625rem !important; }
  .mt-sm-3, .my-sm-3 { margin-top: 0.625rem !important; }
  .mr-sm-3, .mx-sm-3 { margin-right: 0.625rem !important; }
  .mb-sm-3, .my-sm-3 { margin-bottom: 0.625rem !important; }
  .ml-sm-3, .mx-sm-3 { margin-left: 0.625rem !important; }
  .m-sm-4 { margin: 0.9375rem !important; }
  .mt-sm-4, .my-sm-4 { margin-top: 0.9375rem !important; }
  .mr-sm-4, .mx-sm-4 { margin-right: 0.9375rem !important; }
  .mb-sm-4, .my-sm-4 { margin-bottom: 0.9375rem !important; }
  .ml-sm-4, .mx-sm-4 { margin-left: 0.9375rem !important; }
  .m-sm-5 { margin: 1.875rem !important; }
  .mt-sm-5, .my-sm-5 { margin-top: 1.875rem !important; }
  .mr-sm-5, .mx-sm-5 { margin-right: 1.875rem !important; }
  .mb-sm-5, .my-sm-5 { margin-bottom: 1.875rem !important; }
  .ml-sm-5, .mx-sm-5 { margin-left: 1.875rem !important; }
  .p-sm-0 { padding: 0 !important; }
  .pt-sm-0, .py-sm-0 { padding-top: 0 !important; }
  .pr-sm-0, .px-sm-0 { padding-right: 0 !important; }
  .pb-sm-0, .py-sm-0 { padding-bottom: 0 !important; }
  .pl-sm-0, .px-sm-0 { padding-left: 0 !important; }
  .p-sm-1 { padding: 0.15625rem !important; }
  .pt-sm-1, .py-sm-1 { padding-top: 0.15625rem !important; }
  .pr-sm-1, .px-sm-1 { padding-right: 0.15625rem !important; }
  .pb-sm-1, .py-sm-1 { padding-bottom: 0.15625rem !important; }
  .pl-sm-1, .px-sm-1 { padding-left: 0.15625rem !important; }
  .p-sm-2 { padding: 0.3125rem !important; }
  .pt-sm-2, .py-sm-2 { padding-top: 0.3125rem !important; }
  .pr-sm-2, .px-sm-2 { padding-right: 0.3125rem !important; }
  .pb-sm-2, .py-sm-2 { padding-bottom: 0.3125rem !important; }
  .pl-sm-2, .px-sm-2 { padding-left: 0.3125rem !important; }
  .p-sm-3 { padding: 0.625rem !important; }
  .pt-sm-3, .py-sm-3 { padding-top: 0.625rem !important; }
  .pr-sm-3, .px-sm-3 { padding-right: 0.625rem !important; }
  .pb-sm-3, .py-sm-3 { padding-bottom: 0.625rem !important; }
  .pl-sm-3, .px-sm-3 { padding-left: 0.625rem !important; }
  .p-sm-4 { padding: 0.9375rem !important; }
  .pt-sm-4, .py-sm-4 { padding-top: 0.9375rem !important; }
  .pr-sm-4, .px-sm-4 { padding-right: 0.9375rem !important; }
  .pb-sm-4, .py-sm-4 { padding-bottom: 0.9375rem !important; }
  .pl-sm-4, .px-sm-4 { padding-left: 0.9375rem !important; }
  .p-sm-5 { padding: 1.875rem !important; }
  .pt-sm-5, .py-sm-5 { padding-top: 1.875rem !important; }
  .pr-sm-5, .px-sm-5 { padding-right: 1.875rem !important; }
  .pb-sm-5, .py-sm-5 { padding-bottom: 1.875rem !important; }
  .pl-sm-5, .px-sm-5 { padding-left: 1.875rem !important; }
  .m-sm-auto { margin: auto !important; }
  .mt-sm-auto, .my-sm-auto { margin-top: auto !important; }
  .mr-sm-auto, .mx-sm-auto { margin-right: auto !important; }
  .mb-sm-auto, .my-sm-auto { margin-bottom: auto !important; }
  .ml-sm-auto, .mx-sm-auto { margin-left: auto !important; } }

@media (min-width: 768px) { .m-md-0 { margin: 0 !important; }
  .mt-md-0, .my-md-0 { margin-top: 0 !important; }
  .mr-md-0, .mx-md-0 { margin-right: 0 !important; }
  .mb-md-0, .my-md-0 { margin-bottom: 0 !important; }
  .ml-md-0, .mx-md-0 { margin-left: 0 !important; }
  .m-md-1 { margin: 0.15625rem !important; }
  .mt-md-1, .my-md-1 { margin-top: 0.15625rem !important; }
  .mr-md-1, .mx-md-1 { margin-right: 0.15625rem !important; }
  .mb-md-1, .my-md-1 { margin-bottom: 0.15625rem !important; }
  .ml-md-1, .mx-md-1 { margin-left: 0.15625rem !important; }
  .m-md-2 { margin: 0.3125rem !important; }
  .mt-md-2, .my-md-2 { margin-top: 0.3125rem !important; }
  .mr-md-2, .mx-md-2 { margin-right: 0.3125rem !important; }
  .mb-md-2, .my-md-2 { margin-bottom: 0.3125rem !important; }
  .ml-md-2, .mx-md-2 { margin-left: 0.3125rem !important; }
  .m-md-3 { margin: 0.625rem !important; }
  .mt-md-3, .my-md-3 { margin-top: 0.625rem !important; }
  .mr-md-3, .mx-md-3 { margin-right: 0.625rem !important; }
  .mb-md-3, .my-md-3 { margin-bottom: 0.625rem !important; }
  .ml-md-3, .mx-md-3 { margin-left: 0.625rem !important; }
  .m-md-4 { margin: 0.9375rem !important; }
  .mt-md-4, .my-md-4 { margin-top: 0.9375rem !important; }
  .mr-md-4, .mx-md-4 { margin-right: 0.9375rem !important; }
  .mb-md-4, .my-md-4 { margin-bottom: 0.9375rem !important; }
  .ml-md-4, .mx-md-4 { margin-left: 0.9375rem !important; }
  .m-md-5 { margin: 1.875rem !important; }
  .mt-md-5, .my-md-5 { margin-top: 1.875rem !important; }
  .mr-md-5, .mx-md-5 { margin-right: 1.875rem !important; }
  .mb-md-5, .my-md-5 { margin-bottom: 1.875rem !important; }
  .ml-md-5, .mx-md-5 { margin-left: 1.875rem !important; }
  .p-md-0 { padding: 0 !important; }
  .pt-md-0, .py-md-0 { padding-top: 0 !important; }
  .pr-md-0, .px-md-0 { padding-right: 0 !important; }
  .pb-md-0, .py-md-0 { padding-bottom: 0 !important; }
  .pl-md-0, .px-md-0 { padding-left: 0 !important; }
  .p-md-1 { padding: 0.15625rem !important; }
  .pt-md-1, .py-md-1 { padding-top: 0.15625rem !important; }
  .pr-md-1, .px-md-1 { padding-right: 0.15625rem !important; }
  .pb-md-1, .py-md-1 { padding-bottom: 0.15625rem !important; }
  .pl-md-1, .px-md-1 { padding-left: 0.15625rem !important; }
  .p-md-2 { padding: 0.3125rem !important; }
  .pt-md-2, .py-md-2 { padding-top: 0.3125rem !important; }
  .pr-md-2, .px-md-2 { padding-right: 0.3125rem !important; }
  .pb-md-2, .py-md-2 { padding-bottom: 0.3125rem !important; }
  .pl-md-2, .px-md-2 { padding-left: 0.3125rem !important; }
  .p-md-3 { padding: 0.625rem !important; }
  .pt-md-3, .py-md-3 { padding-top: 0.625rem !important; }
  .pr-md-3, .px-md-3 { padding-right: 0.625rem !important; }
  .pb-md-3, .py-md-3 { padding-bottom: 0.625rem !important; }
  .pl-md-3, .px-md-3 { padding-left: 0.625rem !important; }
  .p-md-4 { padding: 0.9375rem !important; }
  .pt-md-4, .py-md-4 { padding-top: 0.9375rem !important; }
  .pr-md-4, .px-md-4 { padding-right: 0.9375rem !important; }
  .pb-md-4, .py-md-4 { padding-bottom: 0.9375rem !important; }
  .pl-md-4, .px-md-4 { padding-left: 0.9375rem !important; }
  .p-md-5 { padding: 1.875rem !important; }
  .pt-md-5, .py-md-5 { padding-top: 1.875rem !important; }
  .pr-md-5, .px-md-5 { padding-right: 1.875rem !important; }
  .pb-md-5, .py-md-5 { padding-bottom: 1.875rem !important; }
  .pl-md-5, .px-md-5 { padding-left: 1.875rem !important; }
  .m-md-auto { margin: auto !important; }
  .mt-md-auto, .my-md-auto { margin-top: auto !important; }
  .mr-md-auto, .mx-md-auto { margin-right: auto !important; }
  .mb-md-auto, .my-md-auto { margin-bottom: auto !important; }
  .ml-md-auto, .mx-md-auto { margin-left: auto !important; } }

@media (min-width: 992px) { .m-lg-0 { margin: 0 !important; }
  .mt-lg-0, .my-lg-0 { margin-top: 0 !important; }
  .mr-lg-0, .mx-lg-0 { margin-right: 0 !important; }
  .mb-lg-0, .my-lg-0 { margin-bottom: 0 !important; }
  .ml-lg-0, .mx-lg-0 { margin-left: 0 !important; }
  .m-lg-1 { margin: 0.15625rem !important; }
  .mt-lg-1, .my-lg-1 { margin-top: 0.15625rem !important; }
  .mr-lg-1, .mx-lg-1 { margin-right: 0.15625rem !important; }
  .mb-lg-1, .my-lg-1 { margin-bottom: 0.15625rem !important; }
  .ml-lg-1, .mx-lg-1 { margin-left: 0.15625rem !important; }
  .m-lg-2 { margin: 0.3125rem !important; }
  .mt-lg-2, .my-lg-2 { margin-top: 0.3125rem !important; }
  .mr-lg-2, .mx-lg-2 { margin-right: 0.3125rem !important; }
  .mb-lg-2, .my-lg-2 { margin-bottom: 0.3125rem !important; }
  .ml-lg-2, .mx-lg-2 { margin-left: 0.3125rem !important; }
  .m-lg-3 { margin: 0.625rem !important; }
  .mt-lg-3, .my-lg-3 { margin-top: 0.625rem !important; }
  .mr-lg-3, .mx-lg-3 { margin-right: 0.625rem !important; }
  .mb-lg-3, .my-lg-3 { margin-bottom: 0.625rem !important; }
  .ml-lg-3, .mx-lg-3 { margin-left: 0.625rem !important; }
  .m-lg-4 { margin: 0.9375rem !important; }
  .mt-lg-4, .my-lg-4 { margin-top: 0.9375rem !important; }
  .mr-lg-4, .mx-lg-4 { margin-right: 0.9375rem !important; }
  .mb-lg-4, .my-lg-4 { margin-bottom: 0.9375rem !important; }
  .ml-lg-4, .mx-lg-4 { margin-left: 0.9375rem !important; }
  .m-lg-5 { margin: 1.875rem !important; }
  .mt-lg-5, .my-lg-5 { margin-top: 1.875rem !important; }
  .mr-lg-5, .mx-lg-5 { margin-right: 1.875rem !important; }
  .mb-lg-5, .my-lg-5 { margin-bottom: 1.875rem !important; }
  .ml-lg-5, .mx-lg-5 { margin-left: 1.875rem !important; }
  .p-lg-0 { padding: 0 !important; }
  .pt-lg-0, .py-lg-0 { padding-top: 0 !important; }
  .pr-lg-0, .px-lg-0 { padding-right: 0 !important; }
  .pb-lg-0, .py-lg-0 { padding-bottom: 0 !important; }
  .pl-lg-0, .px-lg-0 { padding-left: 0 !important; }
  .p-lg-1 { padding: 0.15625rem !important; }
  .pt-lg-1, .py-lg-1 { padding-top: 0.15625rem !important; }
  .pr-lg-1, .px-lg-1 { padding-right: 0.15625rem !important; }
  .pb-lg-1, .py-lg-1 { padding-bottom: 0.15625rem !important; }
  .pl-lg-1, .px-lg-1 { padding-left: 0.15625rem !important; }
  .p-lg-2 { padding: 0.3125rem !important; }
  .pt-lg-2, .py-lg-2 { padding-top: 0.3125rem !important; }
  .pr-lg-2, .px-lg-2 { padding-right: 0.3125rem !important; }
  .pb-lg-2, .py-lg-2 { padding-bottom: 0.3125rem !important; }
  .pl-lg-2, .px-lg-2 { padding-left: 0.3125rem !important; }
  .p-lg-3 { padding: 0.625rem !important; }
  .pt-lg-3, .py-lg-3 { padding-top: 0.625rem !important; }
  .pr-lg-3, .px-lg-3 { padding-right: 0.625rem !important; }
  .pb-lg-3, .py-lg-3 { padding-bottom: 0.625rem !important; }
  .pl-lg-3, .px-lg-3 { padding-left: 0.625rem !important; }
  .p-lg-4 { padding: 0.9375rem !important; }
  .pt-lg-4, .py-lg-4 { padding-top: 0.9375rem !important; }
  .pr-lg-4, .px-lg-4 { padding-right: 0.9375rem !important; }
  .pb-lg-4, .py-lg-4 { padding-bottom: 0.9375rem !important; }
  .pl-lg-4, .px-lg-4 { padding-left: 0.9375rem !important; }
  .p-lg-5 { padding: 1.875rem !important; }
  .pt-lg-5, .py-lg-5 { padding-top: 1.875rem !important; }
  .pr-lg-5, .px-lg-5 { padding-right: 1.875rem !important; }
  .pb-lg-5, .py-lg-5 { padding-bottom: 1.875rem !important; }
  .pl-lg-5, .px-lg-5 { padding-left: 1.875rem !important; }
  .m-lg-auto { margin: auto !important; }
  .mt-lg-auto, .my-lg-auto { margin-top: auto !important; }
  .mr-lg-auto, .mx-lg-auto { margin-right: auto !important; }
  .mb-lg-auto, .my-lg-auto { margin-bottom: auto !important; }
  .ml-lg-auto, .mx-lg-auto { margin-left: auto !important; } }

@media (min-width: 1200px) { .m-xl-0 { margin: 0 !important; }
  .mt-xl-0, .my-xl-0 { margin-top: 0 !important; }
  .mr-xl-0, .mx-xl-0 { margin-right: 0 !important; }
  .mb-xl-0, .my-xl-0 { margin-bottom: 0 !important; }
  .ml-xl-0, .mx-xl-0 { margin-left: 0 !important; }
  .m-xl-1 { margin: 0.15625rem !important; }
  .mt-xl-1, .my-xl-1 { margin-top: 0.15625rem !important; }
  .mr-xl-1, .mx-xl-1 { margin-right: 0.15625rem !important; }
  .mb-xl-1, .my-xl-1 { margin-bottom: 0.15625rem !important; }
  .ml-xl-1, .mx-xl-1 { margin-left: 0.15625rem !important; }
  .m-xl-2 { margin: 0.3125rem !important; }
  .mt-xl-2, .my-xl-2 { margin-top: 0.3125rem !important; }
  .mr-xl-2, .mx-xl-2 { margin-right: 0.3125rem !important; }
  .mb-xl-2, .my-xl-2 { margin-bottom: 0.3125rem !important; }
  .ml-xl-2, .mx-xl-2 { margin-left: 0.3125rem !important; }
  .m-xl-3 { margin: 0.625rem !important; }
  .mt-xl-3, .my-xl-3 { margin-top: 0.625rem !important; }
  .mr-xl-3, .mx-xl-3 { margin-right: 0.625rem !important; }
  .mb-xl-3, .my-xl-3 { margin-bottom: 0.625rem !important; }
  .ml-xl-3, .mx-xl-3 { margin-left: 0.625rem !important; }
  .m-xl-4 { margin: 0.9375rem !important; }
  .mt-xl-4, .my-xl-4 { margin-top: 0.9375rem !important; }
  .mr-xl-4, .mx-xl-4 { margin-right: 0.9375rem !important; }
  .mb-xl-4, .my-xl-4 { margin-bottom: 0.9375rem !important; }
  .ml-xl-4, .mx-xl-4 { margin-left: 0.9375rem !important; }
  .m-xl-5 { margin: 1.875rem !important; }
  .mt-xl-5, .my-xl-5 { margin-top: 1.875rem !important; }
  .mr-xl-5, .mx-xl-5 { margin-right: 1.875rem !important; }
  .mb-xl-5, .my-xl-5 { margin-bottom: 1.875rem !important; }
  .ml-xl-5, .mx-xl-5 { margin-left: 1.875rem !important; }
  .p-xl-0 { padding: 0 !important; }
  .pt-xl-0, .py-xl-0 { padding-top: 0 !important; }
  .pr-xl-0, .px-xl-0 { padding-right: 0 !important; }
  .pb-xl-0, .py-xl-0 { padding-bottom: 0 !important; }
  .pl-xl-0, .px-xl-0 { padding-left: 0 !important; }
  .p-xl-1 { padding: 0.15625rem !important; }
  .pt-xl-1, .py-xl-1 { padding-top: 0.15625rem !important; }
  .pr-xl-1, .px-xl-1 { padding-right: 0.15625rem !important; }
  .pb-xl-1, .py-xl-1 { padding-bottom: 0.15625rem !important; }
  .pl-xl-1, .px-xl-1 { padding-left: 0.15625rem !important; }
  .p-xl-2 { padding: 0.3125rem !important; }
  .pt-xl-2, .py-xl-2 { padding-top: 0.3125rem !important; }
  .pr-xl-2, .px-xl-2 { padding-right: 0.3125rem !important; }
  .pb-xl-2, .py-xl-2 { padding-bottom: 0.3125rem !important; }
  .pl-xl-2, .px-xl-2 { padding-left: 0.3125rem !important; }
  .p-xl-3 { padding: 0.625rem !important; }
  .pt-xl-3, .py-xl-3 { padding-top: 0.625rem !important; }
  .pr-xl-3, .px-xl-3 { padding-right: 0.625rem !important; }
  .pb-xl-3, .py-xl-3 { padding-bottom: 0.625rem !important; }
  .pl-xl-3, .px-xl-3 { padding-left: 0.625rem !important; }
  .p-xl-4 { padding: 0.9375rem !important; }
  .pt-xl-4, .py-xl-4 { padding-top: 0.9375rem !important; }
  .pr-xl-4, .px-xl-4 { padding-right: 0.9375rem !important; }
  .pb-xl-4, .py-xl-4 { padding-bottom: 0.9375rem !important; }
  .pl-xl-4, .px-xl-4 { padding-left: 0.9375rem !important; }
  .p-xl-5 { padding: 1.875rem !important; }
  .pt-xl-5, .py-xl-5 { padding-top: 1.875rem !important; }
  .pr-xl-5, .px-xl-5 { padding-right: 1.875rem !important; }
  .pb-xl-5, .py-xl-5 { padding-bottom: 1.875rem !important; }
  .pl-xl-5, .px-xl-5 { padding-left: 1.875rem !important; }
  .m-xl-auto { margin: auto !important; }
  .mt-xl-auto, .my-xl-auto { margin-top: auto !important; }
  .mr-xl-auto, .mx-xl-auto { margin-right: auto !important; }
  .mb-xl-auto, .my-xl-auto { margin-bottom: auto !important; }
  .ml-xl-auto, .mx-xl-auto { margin-left: auto !important; } }

@media (min-width: 1400px) { .m-vl-0 { margin: 0 !important; }
  .mt-vl-0, .my-vl-0 { margin-top: 0 !important; }
  .mr-vl-0, .mx-vl-0 { margin-right: 0 !important; }
  .mb-vl-0, .my-vl-0 { margin-bottom: 0 !important; }
  .ml-vl-0, .mx-vl-0 { margin-left: 0 !important; }
  .m-vl-1 { margin: 0.15625rem !important; }
  .mt-vl-1, .my-vl-1 { margin-top: 0.15625rem !important; }
  .mr-vl-1, .mx-vl-1 { margin-right: 0.15625rem !important; }
  .mb-vl-1, .my-vl-1 { margin-bottom: 0.15625rem !important; }
  .ml-vl-1, .mx-vl-1 { margin-left: 0.15625rem !important; }
  .m-vl-2 { margin: 0.3125rem !important; }
  .mt-vl-2, .my-vl-2 { margin-top: 0.3125rem !important; }
  .mr-vl-2, .mx-vl-2 { margin-right: 0.3125rem !important; }
  .mb-vl-2, .my-vl-2 { margin-bottom: 0.3125rem !important; }
  .ml-vl-2, .mx-vl-2 { margin-left: 0.3125rem !important; }
  .m-vl-3 { margin: 0.625rem !important; }
  .mt-vl-3, .my-vl-3 { margin-top: 0.625rem !important; }
  .mr-vl-3, .mx-vl-3 { margin-right: 0.625rem !important; }
  .mb-vl-3, .my-vl-3 { margin-bottom: 0.625rem !important; }
  .ml-vl-3, .mx-vl-3 { margin-left: 0.625rem !important; }
  .m-vl-4 { margin: 0.9375rem !important; }
  .mt-vl-4, .my-vl-4 { margin-top: 0.9375rem !important; }
  .mr-vl-4, .mx-vl-4 { margin-right: 0.9375rem !important; }
  .mb-vl-4, .my-vl-4 { margin-bottom: 0.9375rem !important; }
  .ml-vl-4, .mx-vl-4 { margin-left: 0.9375rem !important; }
  .m-vl-5 { margin: 1.875rem !important; }
  .mt-vl-5, .my-vl-5 { margin-top: 1.875rem !important; }
  .mr-vl-5, .mx-vl-5 { margin-right: 1.875rem !important; }
  .mb-vl-5, .my-vl-5 { margin-bottom: 1.875rem !important; }
  .ml-vl-5, .mx-vl-5 { margin-left: 1.875rem !important; }
  .p-vl-0 { padding: 0 !important; }
  .pt-vl-0, .py-vl-0 { padding-top: 0 !important; }
  .pr-vl-0, .px-vl-0 { padding-right: 0 !important; }
  .pb-vl-0, .py-vl-0 { padding-bottom: 0 !important; }
  .pl-vl-0, .px-vl-0 { padding-left: 0 !important; }
  .p-vl-1 { padding: 0.15625rem !important; }
  .pt-vl-1, .py-vl-1 { padding-top: 0.15625rem !important; }
  .pr-vl-1, .px-vl-1 { padding-right: 0.15625rem !important; }
  .pb-vl-1, .py-vl-1 { padding-bottom: 0.15625rem !important; }
  .pl-vl-1, .px-vl-1 { padding-left: 0.15625rem !important; }
  .p-vl-2 { padding: 0.3125rem !important; }
  .pt-vl-2, .py-vl-2 { padding-top: 0.3125rem !important; }
  .pr-vl-2, .px-vl-2 { padding-right: 0.3125rem !important; }
  .pb-vl-2, .py-vl-2 { padding-bottom: 0.3125rem !important; }
  .pl-vl-2, .px-vl-2 { padding-left: 0.3125rem !important; }
  .p-vl-3 { padding: 0.625rem !important; }
  .pt-vl-3, .py-vl-3 { padding-top: 0.625rem !important; }
  .pr-vl-3, .px-vl-3 { padding-right: 0.625rem !important; }
  .pb-vl-3, .py-vl-3 { padding-bottom: 0.625rem !important; }
  .pl-vl-3, .px-vl-3 { padding-left: 0.625rem !important; }
  .p-vl-4 { padding: 0.9375rem !important; }
  .pt-vl-4, .py-vl-4 { padding-top: 0.9375rem !important; }
  .pr-vl-4, .px-vl-4 { padding-right: 0.9375rem !important; }
  .pb-vl-4, .py-vl-4 { padding-bottom: 0.9375rem !important; }
  .pl-vl-4, .px-vl-4 { padding-left: 0.9375rem !important; }
  .p-vl-5 { padding: 1.875rem !important; }
  .pt-vl-5, .py-vl-5 { padding-top: 1.875rem !important; }
  .pr-vl-5, .px-vl-5 { padding-right: 1.875rem !important; }
  .pb-vl-5, .py-vl-5 { padding-bottom: 1.875rem !important; }
  .pl-vl-5, .px-vl-5 { padding-left: 1.875rem !important; }
  .m-vl-auto { margin: auto !important; }
  .mt-vl-auto, .my-vl-auto { margin-top: auto !important; }
  .mr-vl-auto, .mx-vl-auto { margin-right: auto !important; }
  .mb-vl-auto, .my-vl-auto { margin-bottom: auto !important; }
  .ml-vl-auto, .mx-vl-auto { margin-left: auto !important; } }

@media (min-width: 1600px) { .m-sl-0 { margin: 0 !important; }
  .mt-sl-0, .my-sl-0 { margin-top: 0 !important; }
  .mr-sl-0, .mx-sl-0 { margin-right: 0 !important; }
  .mb-sl-0, .my-sl-0 { margin-bottom: 0 !important; }
  .ml-sl-0, .mx-sl-0 { margin-left: 0 !important; }
  .m-sl-1 { margin: 0.15625rem !important; }
  .mt-sl-1, .my-sl-1 { margin-top: 0.15625rem !important; }
  .mr-sl-1, .mx-sl-1 { margin-right: 0.15625rem !important; }
  .mb-sl-1, .my-sl-1 { margin-bottom: 0.15625rem !important; }
  .ml-sl-1, .mx-sl-1 { margin-left: 0.15625rem !important; }
  .m-sl-2 { margin: 0.3125rem !important; }
  .mt-sl-2, .my-sl-2 { margin-top: 0.3125rem !important; }
  .mr-sl-2, .mx-sl-2 { margin-right: 0.3125rem !important; }
  .mb-sl-2, .my-sl-2 { margin-bottom: 0.3125rem !important; }
  .ml-sl-2, .mx-sl-2 { margin-left: 0.3125rem !important; }
  .m-sl-3 { margin: 0.625rem !important; }
  .mt-sl-3, .my-sl-3 { margin-top: 0.625rem !important; }
  .mr-sl-3, .mx-sl-3 { margin-right: 0.625rem !important; }
  .mb-sl-3, .my-sl-3 { margin-bottom: 0.625rem !important; }
  .ml-sl-3, .mx-sl-3 { margin-left: 0.625rem !important; }
  .m-sl-4 { margin: 0.9375rem !important; }
  .mt-sl-4, .my-sl-4 { margin-top: 0.9375rem !important; }
  .mr-sl-4, .mx-sl-4 { margin-right: 0.9375rem !important; }
  .mb-sl-4, .my-sl-4 { margin-bottom: 0.9375rem !important; }
  .ml-sl-4, .mx-sl-4 { margin-left: 0.9375rem !important; }
  .m-sl-5 { margin: 1.875rem !important; }
  .mt-sl-5, .my-sl-5 { margin-top: 1.875rem !important; }
  .mr-sl-5, .mx-sl-5 { margin-right: 1.875rem !important; }
  .mb-sl-5, .my-sl-5 { margin-bottom: 1.875rem !important; }
  .ml-sl-5, .mx-sl-5 { margin-left: 1.875rem !important; }
  .p-sl-0 { padding: 0 !important; }
  .pt-sl-0, .py-sl-0 { padding-top: 0 !important; }
  .pr-sl-0, .px-sl-0 { padding-right: 0 !important; }
  .pb-sl-0, .py-sl-0 { padding-bottom: 0 !important; }
  .pl-sl-0, .px-sl-0 { padding-left: 0 !important; }
  .p-sl-1 { padding: 0.15625rem !important; }
  .pt-sl-1, .py-sl-1 { padding-top: 0.15625rem !important; }
  .pr-sl-1, .px-sl-1 { padding-right: 0.15625rem !important; }
  .pb-sl-1, .py-sl-1 { padding-bottom: 0.15625rem !important; }
  .pl-sl-1, .px-sl-1 { padding-left: 0.15625rem !important; }
  .p-sl-2 { padding: 0.3125rem !important; }
  .pt-sl-2, .py-sl-2 { padding-top: 0.3125rem !important; }
  .pr-sl-2, .px-sl-2 { padding-right: 0.3125rem !important; }
  .pb-sl-2, .py-sl-2 { padding-bottom: 0.3125rem !important; }
  .pl-sl-2, .px-sl-2 { padding-left: 0.3125rem !important; }
  .p-sl-3 { padding: 0.625rem !important; }
  .pt-sl-3, .py-sl-3 { padding-top: 0.625rem !important; }
  .pr-sl-3, .px-sl-3 { padding-right: 0.625rem !important; }
  .pb-sl-3, .py-sl-3 { padding-bottom: 0.625rem !important; }
  .pl-sl-3, .px-sl-3 { padding-left: 0.625rem !important; }
  .p-sl-4 { padding: 0.9375rem !important; }
  .pt-sl-4, .py-sl-4 { padding-top: 0.9375rem !important; }
  .pr-sl-4, .px-sl-4 { padding-right: 0.9375rem !important; }
  .pb-sl-4, .py-sl-4 { padding-bottom: 0.9375rem !important; }
  .pl-sl-4, .px-sl-4 { padding-left: 0.9375rem !important; }
  .p-sl-5 { padding: 1.875rem !important; }
  .pt-sl-5, .py-sl-5 { padding-top: 1.875rem !important; }
  .pr-sl-5, .px-sl-5 { padding-right: 1.875rem !important; }
  .pb-sl-5, .py-sl-5 { padding-bottom: 1.875rem !important; }
  .pl-sl-5, .px-sl-5 { padding-left: 1.875rem !important; }
  .m-sl-auto { margin: auto !important; }
  .mt-sl-auto, .my-sl-auto { margin-top: auto !important; }
  .mr-sl-auto, .mx-sl-auto { margin-right: auto !important; }
  .mb-sl-auto, .my-sl-auto { margin-bottom: auto !important; }
  .ml-sl-auto, .mx-sl-auto { margin-left: auto !important; } }

.d-none { display: none !important; }

.d-inline { display: inline !important; }

.d-inline-block { display: inline-block !important; }

.d-block { display: block !important; }

.d-table { display: table !important; }

.d-table-row { display: table-row !important; }

.d-table-cell { display: table-cell !important; }

.d-flex { display: flex !important; }

.d-inline-flex { display: inline-flex !important; }

@media (min-width: 576px) { .d-sm-none { display: none !important; }
  .d-sm-inline { display: inline !important; }
  .d-sm-inline-block { display: inline-block !important; }
  .d-sm-block { display: block !important; }
  .d-sm-table { display: table !important; }
  .d-sm-table-row { display: table-row !important; }
  .d-sm-table-cell { display: table-cell !important; }
  .d-sm-flex { display: flex !important; }
  .d-sm-inline-flex { display: inline-flex !important; } }

@media (min-width: 768px) { .d-md-none { display: none !important; }
  .d-md-inline { display: inline !important; }
  .d-md-inline-block { display: inline-block !important; }
  .d-md-block { display: block !important; }
  .d-md-table { display: table !important; }
  .d-md-table-row { display: table-row !important; }
  .d-md-table-cell { display: table-cell !important; }
  .d-md-flex { display: flex !important; }
  .d-md-inline-flex { display: inline-flex !important; } }

@media (min-width: 992px) { .d-lg-none { display: none !important; }
  .d-lg-inline { display: inline !important; }
  .d-lg-inline-block { display: inline-block !important; }
  .d-lg-block { display: block !important; }
  .d-lg-table { display: table !important; }
  .d-lg-table-row { display: table-row !important; }
  .d-lg-table-cell { display: table-cell !important; }
  .d-lg-flex { display: flex !important; }
  .d-lg-inline-flex { display: inline-flex !important; } }

@media (min-width: 1200px) { .d-xl-none { display: none !important; }
  .d-xl-inline { display: inline !important; }
  .d-xl-inline-block { display: inline-block !important; }
  .d-xl-block { display: block !important; }
  .d-xl-table { display: table !important; }
  .d-xl-table-row { display: table-row !important; }
  .d-xl-table-cell { display: table-cell !important; }
  .d-xl-flex { display: flex !important; }
  .d-xl-inline-flex { display: inline-flex !important; } }

@media (min-width: 1400px) { .d-vl-none { display: none !important; }
  .d-vl-inline { display: inline !important; }
  .d-vl-inline-block { display: inline-block !important; }
  .d-vl-block { display: block !important; }
  .d-vl-table { display: table !important; }
  .d-vl-table-row { display: table-row !important; }
  .d-vl-table-cell { display: table-cell !important; }
  .d-vl-flex { display: flex !important; }
  .d-vl-inline-flex { display: inline-flex !important; } }

@media (min-width: 1600px) { .d-sl-none { display: none !important; }
  .d-sl-inline { display: inline !important; }
  .d-sl-inline-block { display: inline-block !important; }
  .d-sl-block { display: block !important; }
  .d-sl-table { display: table !important; }
  .d-sl-table-row { display: table-row !important; }
  .d-sl-table-cell { display: table-cell !important; }
  .d-sl-flex { display: flex !important; }
  .d-sl-inline-flex { display: inline-flex !important; } }

.flex-row { flex-direction: row !important; }

.flex-column { flex-direction: column !important; }

.flex-row-reverse { flex-direction: row-reverse !important; }

.flex-column-reverse { flex-direction: column-reverse !important; }

.flex-wrap { flex-wrap: wrap !important; }

.flex-nowrap { flex-wrap: nowrap !important; }

.flex-wrap-reverse { flex-wrap: wrap-reverse !important; }

.flex-fill { flex: 1 1 auto !important; }

.flex-grow-0 { flex-grow: 0 !important; }

.flex-grow-1 { flex-grow: 1 !important; }

.flex-shrink-0 { flex-shrink: 0 !important; }

.flex-shrink-1 { flex-shrink: 1 !important; }

.justify-content-start { justify-content: flex-start !important; }

.justify-content-end { justify-content: flex-end !important; }

.justify-content-center { justify-content: center !important; }

.justify-content-between { justify-content: space-between !important; }

.justify-content-around { justify-content: space-around !important; }

.align-items-start { align-items: flex-start !important; }

.align-items-end { align-items: flex-end !important; }

.align-items-center { align-items: center !important; }

.align-items-baseline { align-items: baseline !important; }

.align-items-stretch { align-items: stretch !important; }

.align-content-start { align-content: flex-start !important; }

.align-content-end { align-content: flex-end !important; }

.align-content-center { align-content: center !important; }

.align-content-between { align-content: space-between !important; }

.align-content-around { align-content: space-around !important; }

.align-content-stretch { align-content: stretch !important; }

.align-self-auto { align-self: auto !important; }

.align-self-start { align-self: flex-start !important; }

.align-self-end { align-self: flex-end !important; }

.align-self-center { align-self: center !important; }

.align-self-baseline { align-self: baseline !important; }

.align-self-stretch { align-self: stretch !important; }

@media (min-width: 576px) { .flex-sm-row { flex-direction: row !important; }
  .flex-sm-column { flex-direction: column !important; }
  .flex-sm-row-reverse { flex-direction: row-reverse !important; }
  .flex-sm-column-reverse { flex-direction: column-reverse !important; }
  .flex-sm-wrap { flex-wrap: wrap !important; }
  .flex-sm-nowrap { flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse { flex-wrap: wrap-reverse !important; }
  .flex-sm-fill { flex: 1 1 auto !important; }
  .flex-sm-grow-0 { flex-grow: 0 !important; }
  .flex-sm-grow-1 { flex-grow: 1 !important; }
  .flex-sm-shrink-0 { flex-shrink: 0 !important; }
  .flex-sm-shrink-1 { flex-shrink: 1 !important; }
  .justify-content-sm-start { justify-content: flex-start !important; }
  .justify-content-sm-end { justify-content: flex-end !important; }
  .justify-content-sm-center { justify-content: center !important; }
  .justify-content-sm-between { justify-content: space-between !important; }
  .justify-content-sm-around { justify-content: space-around !important; }
  .align-items-sm-start { align-items: flex-start !important; }
  .align-items-sm-end { align-items: flex-end !important; }
  .align-items-sm-center { align-items: center !important; }
  .align-items-sm-baseline { align-items: baseline !important; }
  .align-items-sm-stretch { align-items: stretch !important; }
  .align-content-sm-start { align-content: flex-start !important; }
  .align-content-sm-end { align-content: flex-end !important; }
  .align-content-sm-center { align-content: center !important; }
  .align-content-sm-between { align-content: space-between !important; }
  .align-content-sm-around { align-content: space-around !important; }
  .align-content-sm-stretch { align-content: stretch !important; }
  .align-self-sm-auto { align-self: auto !important; }
  .align-self-sm-start { align-self: flex-start !important; }
  .align-self-sm-end { align-self: flex-end !important; }
  .align-self-sm-center { align-self: center !important; }
  .align-self-sm-baseline { align-self: baseline !important; }
  .align-self-sm-stretch { align-self: stretch !important; } }

@media (min-width: 768px) { .flex-md-row { flex-direction: row !important; }
  .flex-md-column { flex-direction: column !important; }
  .flex-md-row-reverse { flex-direction: row-reverse !important; }
  .flex-md-column-reverse { flex-direction: column-reverse !important; }
  .flex-md-wrap { flex-wrap: wrap !important; }
  .flex-md-nowrap { flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse { flex-wrap: wrap-reverse !important; }
  .flex-md-fill { flex: 1 1 auto !important; }
  .flex-md-grow-0 { flex-grow: 0 !important; }
  .flex-md-grow-1 { flex-grow: 1 !important; }
  .flex-md-shrink-0 { flex-shrink: 0 !important; }
  .flex-md-shrink-1 { flex-shrink: 1 !important; }
  .justify-content-md-start { justify-content: flex-start !important; }
  .justify-content-md-end { justify-content: flex-end !important; }
  .justify-content-md-center { justify-content: center !important; }
  .justify-content-md-between { justify-content: space-between !important; }
  .justify-content-md-around { justify-content: space-around !important; }
  .align-items-md-start { align-items: flex-start !important; }
  .align-items-md-end { align-items: flex-end !important; }
  .align-items-md-center { align-items: center !important; }
  .align-items-md-baseline { align-items: baseline !important; }
  .align-items-md-stretch { align-items: stretch !important; }
  .align-content-md-start { align-content: flex-start !important; }
  .align-content-md-end { align-content: flex-end !important; }
  .align-content-md-center { align-content: center !important; }
  .align-content-md-between { align-content: space-between !important; }
  .align-content-md-around { align-content: space-around !important; }
  .align-content-md-stretch { align-content: stretch !important; }
  .align-self-md-auto { align-self: auto !important; }
  .align-self-md-start { align-self: flex-start !important; }
  .align-self-md-end { align-self: flex-end !important; }
  .align-self-md-center { align-self: center !important; }
  .align-self-md-baseline { align-self: baseline !important; }
  .align-self-md-stretch { align-self: stretch !important; } }

@media (min-width: 992px) { .flex-lg-row { flex-direction: row !important; }
  .flex-lg-column { flex-direction: column !important; }
  .flex-lg-row-reverse { flex-direction: row-reverse !important; }
  .flex-lg-column-reverse { flex-direction: column-reverse !important; }
  .flex-lg-wrap { flex-wrap: wrap !important; }
  .flex-lg-nowrap { flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse { flex-wrap: wrap-reverse !important; }
  .flex-lg-fill { flex: 1 1 auto !important; }
  .flex-lg-grow-0 { flex-grow: 0 !important; }
  .flex-lg-grow-1 { flex-grow: 1 !important; }
  .flex-lg-shrink-0 { flex-shrink: 0 !important; }
  .flex-lg-shrink-1 { flex-shrink: 1 !important; }
  .justify-content-lg-start { justify-content: flex-start !important; }
  .justify-content-lg-end { justify-content: flex-end !important; }
  .justify-content-lg-center { justify-content: center !important; }
  .justify-content-lg-between { justify-content: space-between !important; }
  .justify-content-lg-around { justify-content: space-around !important; }
  .align-items-lg-start { align-items: flex-start !important; }
  .align-items-lg-end { align-items: flex-end !important; }
  .align-items-lg-center { align-items: center !important; }
  .align-items-lg-baseline { align-items: baseline !important; }
  .align-items-lg-stretch { align-items: stretch !important; }
  .align-content-lg-start { align-content: flex-start !important; }
  .align-content-lg-end { align-content: flex-end !important; }
  .align-content-lg-center { align-content: center !important; }
  .align-content-lg-between { align-content: space-between !important; }
  .align-content-lg-around { align-content: space-around !important; }
  .align-content-lg-stretch { align-content: stretch !important; }
  .align-self-lg-auto { align-self: auto !important; }
  .align-self-lg-start { align-self: flex-start !important; }
  .align-self-lg-end { align-self: flex-end !important; }
  .align-self-lg-center { align-self: center !important; }
  .align-self-lg-baseline { align-self: baseline !important; }
  .align-self-lg-stretch { align-self: stretch !important; } }

@media (min-width: 1200px) { .flex-xl-row { flex-direction: row !important; }
  .flex-xl-column { flex-direction: column !important; }
  .flex-xl-row-reverse { flex-direction: row-reverse !important; }
  .flex-xl-column-reverse { flex-direction: column-reverse !important; }
  .flex-xl-wrap { flex-wrap: wrap !important; }
  .flex-xl-nowrap { flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse { flex-wrap: wrap-reverse !important; }
  .flex-xl-fill { flex: 1 1 auto !important; }
  .flex-xl-grow-0 { flex-grow: 0 !important; }
  .flex-xl-grow-1 { flex-grow: 1 !important; }
  .flex-xl-shrink-0 { flex-shrink: 0 !important; }
  .flex-xl-shrink-1 { flex-shrink: 1 !important; }
  .justify-content-xl-start { justify-content: flex-start !important; }
  .justify-content-xl-end { justify-content: flex-end !important; }
  .justify-content-xl-center { justify-content: center !important; }
  .justify-content-xl-between { justify-content: space-between !important; }
  .justify-content-xl-around { justify-content: space-around !important; }
  .align-items-xl-start { align-items: flex-start !important; }
  .align-items-xl-end { align-items: flex-end !important; }
  .align-items-xl-center { align-items: center !important; }
  .align-items-xl-baseline { align-items: baseline !important; }
  .align-items-xl-stretch { align-items: stretch !important; }
  .align-content-xl-start { align-content: flex-start !important; }
  .align-content-xl-end { align-content: flex-end !important; }
  .align-content-xl-center { align-content: center !important; }
  .align-content-xl-between { align-content: space-between !important; }
  .align-content-xl-around { align-content: space-around !important; }
  .align-content-xl-stretch { align-content: stretch !important; }
  .align-self-xl-auto { align-self: auto !important; }
  .align-self-xl-start { align-self: flex-start !important; }
  .align-self-xl-end { align-self: flex-end !important; }
  .align-self-xl-center { align-self: center !important; }
  .align-self-xl-baseline { align-self: baseline !important; }
  .align-self-xl-stretch { align-self: stretch !important; } }

@media (min-width: 1400px) { .flex-vl-row { flex-direction: row !important; }
  .flex-vl-column { flex-direction: column !important; }
  .flex-vl-row-reverse { flex-direction: row-reverse !important; }
  .flex-vl-column-reverse { flex-direction: column-reverse !important; }
  .flex-vl-wrap { flex-wrap: wrap !important; }
  .flex-vl-nowrap { flex-wrap: nowrap !important; }
  .flex-vl-wrap-reverse { flex-wrap: wrap-reverse !important; }
  .flex-vl-fill { flex: 1 1 auto !important; }
  .flex-vl-grow-0 { flex-grow: 0 !important; }
  .flex-vl-grow-1 { flex-grow: 1 !important; }
  .flex-vl-shrink-0 { flex-shrink: 0 !important; }
  .flex-vl-shrink-1 { flex-shrink: 1 !important; }
  .justify-content-vl-start { justify-content: flex-start !important; }
  .justify-content-vl-end { justify-content: flex-end !important; }
  .justify-content-vl-center { justify-content: center !important; }
  .justify-content-vl-between { justify-content: space-between !important; }
  .justify-content-vl-around { justify-content: space-around !important; }
  .align-items-vl-start { align-items: flex-start !important; }
  .align-items-vl-end { align-items: flex-end !important; }
  .align-items-vl-center { align-items: center !important; }
  .align-items-vl-baseline { align-items: baseline !important; }
  .align-items-vl-stretch { align-items: stretch !important; }
  .align-content-vl-start { align-content: flex-start !important; }
  .align-content-vl-end { align-content: flex-end !important; }
  .align-content-vl-center { align-content: center !important; }
  .align-content-vl-between { align-content: space-between !important; }
  .align-content-vl-around { align-content: space-around !important; }
  .align-content-vl-stretch { align-content: stretch !important; }
  .align-self-vl-auto { align-self: auto !important; }
  .align-self-vl-start { align-self: flex-start !important; }
  .align-self-vl-end { align-self: flex-end !important; }
  .align-self-vl-center { align-self: center !important; }
  .align-self-vl-baseline { align-self: baseline !important; }
  .align-self-vl-stretch { align-self: stretch !important; } }

@media (min-width: 1600px) { .flex-sl-row { flex-direction: row !important; }
  .flex-sl-column { flex-direction: column !important; }
  .flex-sl-row-reverse { flex-direction: row-reverse !important; }
  .flex-sl-column-reverse { flex-direction: column-reverse !important; }
  .flex-sl-wrap { flex-wrap: wrap !important; }
  .flex-sl-nowrap { flex-wrap: nowrap !important; }
  .flex-sl-wrap-reverse { flex-wrap: wrap-reverse !important; }
  .flex-sl-fill { flex: 1 1 auto !important; }
  .flex-sl-grow-0 { flex-grow: 0 !important; }
  .flex-sl-grow-1 { flex-grow: 1 !important; }
  .flex-sl-shrink-0 { flex-shrink: 0 !important; }
  .flex-sl-shrink-1 { flex-shrink: 1 !important; }
  .justify-content-sl-start { justify-content: flex-start !important; }
  .justify-content-sl-end { justify-content: flex-end !important; }
  .justify-content-sl-center { justify-content: center !important; }
  .justify-content-sl-between { justify-content: space-between !important; }
  .justify-content-sl-around { justify-content: space-around !important; }
  .align-items-sl-start { align-items: flex-start !important; }
  .align-items-sl-end { align-items: flex-end !important; }
  .align-items-sl-center { align-items: center !important; }
  .align-items-sl-baseline { align-items: baseline !important; }
  .align-items-sl-stretch { align-items: stretch !important; }
  .align-content-sl-start { align-content: flex-start !important; }
  .align-content-sl-end { align-content: flex-end !important; }
  .align-content-sl-center { align-content: center !important; }
  .align-content-sl-between { align-content: space-between !important; }
  .align-content-sl-around { align-content: space-around !important; }
  .align-content-sl-stretch { align-content: stretch !important; }
  .align-self-sl-auto { align-self: auto !important; }
  .align-self-sl-start { align-self: flex-start !important; }
  .align-self-sl-end { align-self: flex-end !important; }
  .align-self-sl-center { align-self: center !important; }
  .align-self-sl-baseline { align-self: baseline !important; }
  .align-self-sl-stretch { align-self: stretch !important; } }

.container, .container-fluid, .container-sm, .container-md, .container-lg, .container-xl, .container-vl, .container-sl { width: 100%; padding-right: 8px; padding-left: 8px; margin-right: auto; margin-left: auto; }

@media (min-width: 576px) { .container, .container-sm { max-width: 540px; } }

@media (min-width: 768px) { .container, .container-sm, .container-md { max-width: 720px; } }

@media (min-width: 992px) { .container, .container-sm, .container-md, .container-lg { max-width: 960px; } }

@media (min-width: 1200px) { .container, .container-sm, .container-md, .container-lg, .container-xl { max-width: 1200px; } }

@media (min-width: 1400px) { .container, .container-sm, .container-md, .container-lg, .container-xl, .container-vl { max-width: 1340px; } }

@media (min-width: 1600px) { .container, .container-sm, .container-md, .container-lg, .container-xl, .container-vl, .container-sl { max-width: 1440px; } }

.row { display: flex; flex-wrap: wrap; margin-right: -8px; margin-left: -8px; }

.no-gutters { margin-right: 0; margin-left: 0; }

.no-gutters > .col, .no-gutters > [class*="col-"] { padding-right: 0; padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col, .col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm, .col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md, .col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg, .col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl, .col-xl-auto, .col-vl-1, .col-vl-2, .col-vl-3, .col-vl-4, .col-vl-5, .col-vl-6, .col-vl-7, .col-vl-8, .col-vl-9, .col-vl-10, .col-vl-11, .col-vl-12, .col-vl, .col-vl-auto, .col-sl-1, .col-sl-2, .col-sl-3, .col-sl-4, .col-sl-5, .col-sl-6, .col-sl-7, .col-sl-8, .col-sl-9, .col-sl-10, .col-sl-11, .col-sl-12, .col-sl, .col-sl-auto { position: relative; width: 100%; padding-right: 8px; padding-left: 8px; }

.col { flex-basis: 0; flex-grow: 1; max-width: 100%; }

.row-cols-1 > * { flex: 0 0 100%; max-width: 100%; }

.row-cols-2 > * { flex: 0 0 50%; max-width: 50%; }

.row-cols-3 > * { flex: 0 0 33.33333%; max-width: 33.33333%; }

.row-cols-4 > * { flex: 0 0 25%; max-width: 25%; }

.row-cols-5 > * { flex: 0 0 20%; max-width: 20%; }

.row-cols-6 > * { flex: 0 0 16.66667%; max-width: 16.66667%; }

.col-auto { flex: 0 0 auto; width: auto; max-width: 100%; }

.col-1 { flex: 0 0 8.33333%; max-width: 8.33333%; }

.col-2 { flex: 0 0 16.66667%; max-width: 16.66667%; }

.col-3 { flex: 0 0 25%; max-width: 25%; }

.col-4 { flex: 0 0 33.33333%; max-width: 33.33333%; }

.col-5 { flex: 0 0 41.66667%; max-width: 41.66667%; }

.col-6 { flex: 0 0 50%; max-width: 50%; }

.col-7 { flex: 0 0 58.33333%; max-width: 58.33333%; }

.col-8 { flex: 0 0 66.66667%; max-width: 66.66667%; }

.col-9 { flex: 0 0 75%; max-width: 75%; }

.col-10 { flex: 0 0 83.33333%; max-width: 83.33333%; }

.col-11 { flex: 0 0 91.66667%; max-width: 91.66667%; }

.col-12 { flex: 0 0 100%; max-width: 100%; }

.order-first { order: -1; }

.order-last { order: 13; }

.order-0 { order: 0; }

.order-1 { order: 1; }

.order-2 { order: 2; }

.order-3 { order: 3; }

.order-4 { order: 4; }

.order-5 { order: 5; }

.order-6 { order: 6; }

.order-7 { order: 7; }

.order-8 { order: 8; }

.order-9 { order: 9; }

.order-10 { order: 10; }

.order-11 { order: 11; }

.order-12 { order: 12; }

.offset-1 { margin-left: 8.33333%; }

.offset-2 { margin-left: 16.66667%; }

.offset-3 { margin-left: 25%; }

.offset-4 { margin-left: 33.33333%; }

.offset-5 { margin-left: 41.66667%; }

.offset-6 { margin-left: 50%; }

.offset-7 { margin-left: 58.33333%; }

.offset-8 { margin-left: 66.66667%; }

.offset-9 { margin-left: 75%; }

.offset-10 { margin-left: 83.33333%; }

.offset-11 { margin-left: 91.66667%; }

@media (min-width: 576px) { .col-sm { flex-basis: 0; flex-grow: 1; max-width: 100%; }
  .row-cols-sm-1 > * { flex: 0 0 100%; max-width: 100%; }
  .row-cols-sm-2 > * { flex: 0 0 50%; max-width: 50%; }
  .row-cols-sm-3 > * { flex: 0 0 33.33333%; max-width: 33.33333%; }
  .row-cols-sm-4 > * { flex: 0 0 25%; max-width: 25%; }
  .row-cols-sm-5 > * { flex: 0 0 20%; max-width: 20%; }
  .row-cols-sm-6 > * { flex: 0 0 16.66667%; max-width: 16.66667%; }
  .col-sm-auto { flex: 0 0 auto; width: auto; max-width: 100%; }
  .col-sm-1 { flex: 0 0 8.33333%; max-width: 8.33333%; }
  .col-sm-2 { flex: 0 0 16.66667%; max-width: 16.66667%; }
  .col-sm-3 { flex: 0 0 25%; max-width: 25%; }
  .col-sm-4 { flex: 0 0 33.33333%; max-width: 33.33333%; }
  .col-sm-5 { flex: 0 0 41.66667%; max-width: 41.66667%; }
  .col-sm-6 { flex: 0 0 50%; max-width: 50%; }
  .col-sm-7 { flex: 0 0 58.33333%; max-width: 58.33333%; }
  .col-sm-8 { flex: 0 0 66.66667%; max-width: 66.66667%; }
  .col-sm-9 { flex: 0 0 75%; max-width: 75%; }
  .col-sm-10 { flex: 0 0 83.33333%; max-width: 83.33333%; }
  .col-sm-11 { flex: 0 0 91.66667%; max-width: 91.66667%; }
  .col-sm-12 { flex: 0 0 100%; max-width: 100%; }
  .order-sm-first { order: -1; }
  .order-sm-last { order: 13; }
  .order-sm-0 { order: 0; }
  .order-sm-1 { order: 1; }
  .order-sm-2 { order: 2; }
  .order-sm-3 { order: 3; }
  .order-sm-4 { order: 4; }
  .order-sm-5 { order: 5; }
  .order-sm-6 { order: 6; }
  .order-sm-7 { order: 7; }
  .order-sm-8 { order: 8; }
  .order-sm-9 { order: 9; }
  .order-sm-10 { order: 10; }
  .order-sm-11 { order: 11; }
  .order-sm-12 { order: 12; }
  .offset-sm-0 { margin-left: 0; }
  .offset-sm-1 { margin-left: 8.33333%; }
  .offset-sm-2 { margin-left: 16.66667%; }
  .offset-sm-3 { margin-left: 25%; }
  .offset-sm-4 { margin-left: 33.33333%; }
  .offset-sm-5 { margin-left: 41.66667%; }
  .offset-sm-6 { margin-left: 50%; }
  .offset-sm-7 { margin-left: 58.33333%; }
  .offset-sm-8 { margin-left: 66.66667%; }
  .offset-sm-9 { margin-left: 75%; }
  .offset-sm-10 { margin-left: 83.33333%; }
  .offset-sm-11 { margin-left: 91.66667%; } }

@media (min-width: 768px) { .col-md { flex-basis: 0; flex-grow: 1; max-width: 100%; }
  .row-cols-md-1 > * { flex: 0 0 100%; max-width: 100%; }
  .row-cols-md-2 > * { flex: 0 0 50%; max-width: 50%; }
  .row-cols-md-3 > * { flex: 0 0 33.33333%; max-width: 33.33333%; }
  .row-cols-md-4 > * { flex: 0 0 25%; max-width: 25%; }
  .row-cols-md-5 > * { flex: 0 0 20%; max-width: 20%; }
  .row-cols-md-6 > * { flex: 0 0 16.66667%; max-width: 16.66667%; }
  .col-md-auto { flex: 0 0 auto; width: auto; max-width: 100%; }
  .col-md-1 { flex: 0 0 8.33333%; max-width: 8.33333%; }
  .col-md-2 { flex: 0 0 16.66667%; max-width: 16.66667%; }
  .col-md-3 { flex: 0 0 25%; max-width: 25%; }
  .col-md-4 { flex: 0 0 33.33333%; max-width: 33.33333%; }
  .col-md-5 { flex: 0 0 41.66667%; max-width: 41.66667%; }
  .col-md-6 { flex: 0 0 50%; max-width: 50%; }
  .col-md-7 { flex: 0 0 58.33333%; max-width: 58.33333%; }
  .col-md-8 { flex: 0 0 66.66667%; max-width: 66.66667%; }
  .col-md-9 { flex: 0 0 75%; max-width: 75%; }
  .col-md-10 { flex: 0 0 83.33333%; max-width: 83.33333%; }
  .col-md-11 { flex: 0 0 91.66667%; max-width: 91.66667%; }
  .col-md-12 { flex: 0 0 100%; max-width: 100%; }
  .order-md-first { order: -1; }
  .order-md-last { order: 13; }
  .order-md-0 { order: 0; }
  .order-md-1 { order: 1; }
  .order-md-2 { order: 2; }
  .order-md-3 { order: 3; }
  .order-md-4 { order: 4; }
  .order-md-5 { order: 5; }
  .order-md-6 { order: 6; }
  .order-md-7 { order: 7; }
  .order-md-8 { order: 8; }
  .order-md-9 { order: 9; }
  .order-md-10 { order: 10; }
  .order-md-11 { order: 11; }
  .order-md-12 { order: 12; }
  .offset-md-0 { margin-left: 0; }
  .offset-md-1 { margin-left: 8.33333%; }
  .offset-md-2 { margin-left: 16.66667%; }
  .offset-md-3 { margin-left: 25%; }
  .offset-md-4 { margin-left: 33.33333%; }
  .offset-md-5 { margin-left: 41.66667%; }
  .offset-md-6 { margin-left: 50%; }
  .offset-md-7 { margin-left: 58.33333%; }
  .offset-md-8 { margin-left: 66.66667%; }
  .offset-md-9 { margin-left: 75%; }
  .offset-md-10 { margin-left: 83.33333%; }
  .offset-md-11 { margin-left: 91.66667%; } }

@media (min-width: 992px) { .col-lg { flex-basis: 0; flex-grow: 1; max-width: 100%; }
  .row-cols-lg-1 > * { flex: 0 0 100%; max-width: 100%; }
  .row-cols-lg-2 > * { flex: 0 0 50%; max-width: 50%; }
  .row-cols-lg-3 > * { flex: 0 0 33.33333%; max-width: 33.33333%; }
  .row-cols-lg-4 > * { flex: 0 0 25%; max-width: 25%; }
  .row-cols-lg-5 > * { flex: 0 0 20%; max-width: 20%; }
  .row-cols-lg-6 > * { flex: 0 0 16.66667%; max-width: 16.66667%; }
  .col-lg-auto { flex: 0 0 auto; width: auto; max-width: 100%; }
  .col-lg-1 { flex: 0 0 8.33333%; max-width: 8.33333%; }
  .col-lg-2 { flex: 0 0 16.66667%; max-width: 16.66667%; }
  .col-lg-3 { flex: 0 0 25%; max-width: 25%; }
  .col-lg-4 { flex: 0 0 33.33333%; max-width: 33.33333%; }
  .col-lg-5 { flex: 0 0 41.66667%; max-width: 41.66667%; }
  .col-lg-6 { flex: 0 0 50%; max-width: 50%; }
  .col-lg-7 { flex: 0 0 58.33333%; max-width: 58.33333%; }
  .col-lg-8 { flex: 0 0 66.66667%; max-width: 66.66667%; }
  .col-lg-9 { flex: 0 0 75%; max-width: 75%; }
  .col-lg-10 { flex: 0 0 83.33333%; max-width: 83.33333%; }
  .col-lg-11 { flex: 0 0 91.66667%; max-width: 91.66667%; }
  .col-lg-12 { flex: 0 0 100%; max-width: 100%; }
  .order-lg-first { order: -1; }
  .order-lg-last { order: 13; }
  .order-lg-0 { order: 0; }
  .order-lg-1 { order: 1; }
  .order-lg-2 { order: 2; }
  .order-lg-3 { order: 3; }
  .order-lg-4 { order: 4; }
  .order-lg-5 { order: 5; }
  .order-lg-6 { order: 6; }
  .order-lg-7 { order: 7; }
  .order-lg-8 { order: 8; }
  .order-lg-9 { order: 9; }
  .order-lg-10 { order: 10; }
  .order-lg-11 { order: 11; }
  .order-lg-12 { order: 12; }
  .offset-lg-0 { margin-left: 0; }
  .offset-lg-1 { margin-left: 8.33333%; }
  .offset-lg-2 { margin-left: 16.66667%; }
  .offset-lg-3 { margin-left: 25%; }
  .offset-lg-4 { margin-left: 33.33333%; }
  .offset-lg-5 { margin-left: 41.66667%; }
  .offset-lg-6 { margin-left: 50%; }
  .offset-lg-7 { margin-left: 58.33333%; }
  .offset-lg-8 { margin-left: 66.66667%; }
  .offset-lg-9 { margin-left: 75%; }
  .offset-lg-10 { margin-left: 83.33333%; }
  .offset-lg-11 { margin-left: 91.66667%; } }

@media (min-width: 1200px) { .col-xl { flex-basis: 0; flex-grow: 1; max-width: 100%; }
  .row-cols-xl-1 > * { flex: 0 0 100%; max-width: 100%; }
  .row-cols-xl-2 > * { flex: 0 0 50%; max-width: 50%; }
  .row-cols-xl-3 > * { flex: 0 0 33.33333%; max-width: 33.33333%; }
  .row-cols-xl-4 > * { flex: 0 0 25%; max-width: 25%; }
  .row-cols-xl-5 > * { flex: 0 0 20%; max-width: 20%; }
  .row-cols-xl-6 > * { flex: 0 0 16.66667%; max-width: 16.66667%; }
  .col-xl-auto { flex: 0 0 auto; width: auto; max-width: 100%; }
  .col-xl-1 { flex: 0 0 8.33333%; max-width: 8.33333%; }
  .col-xl-2 { flex: 0 0 16.66667%; max-width: 16.66667%; }
  .col-xl-3 { flex: 0 0 25%; max-width: 25%; }
  .col-xl-4 { flex: 0 0 33.33333%; max-width: 33.33333%; }
  .col-xl-5 { flex: 0 0 41.66667%; max-width: 41.66667%; }
  .col-xl-6 { flex: 0 0 50%; max-width: 50%; }
  .col-xl-7 { flex: 0 0 58.33333%; max-width: 58.33333%; }
  .col-xl-8 { flex: 0 0 66.66667%; max-width: 66.66667%; }
  .col-xl-9 { flex: 0 0 75%; max-width: 75%; }
  .col-xl-10 { flex: 0 0 83.33333%; max-width: 83.33333%; }
  .col-xl-11 { flex: 0 0 91.66667%; max-width: 91.66667%; }
  .col-xl-12 { flex: 0 0 100%; max-width: 100%; }
  .order-xl-first { order: -1; }
  .order-xl-last { order: 13; }
  .order-xl-0 { order: 0; }
  .order-xl-1 { order: 1; }
  .order-xl-2 { order: 2; }
  .order-xl-3 { order: 3; }
  .order-xl-4 { order: 4; }
  .order-xl-5 { order: 5; }
  .order-xl-6 { order: 6; }
  .order-xl-7 { order: 7; }
  .order-xl-8 { order: 8; }
  .order-xl-9 { order: 9; }
  .order-xl-10 { order: 10; }
  .order-xl-11 { order: 11; }
  .order-xl-12 { order: 12; }
  .offset-xl-0 { margin-left: 0; }
  .offset-xl-1 { margin-left: 8.33333%; }
  .offset-xl-2 { margin-left: 16.66667%; }
  .offset-xl-3 { margin-left: 25%; }
  .offset-xl-4 { margin-left: 33.33333%; }
  .offset-xl-5 { margin-left: 41.66667%; }
  .offset-xl-6 { margin-left: 50%; }
  .offset-xl-7 { margin-left: 58.33333%; }
  .offset-xl-8 { margin-left: 66.66667%; }
  .offset-xl-9 { margin-left: 75%; }
  .offset-xl-10 { margin-left: 83.33333%; }
  .offset-xl-11 { margin-left: 91.66667%; } }

@media (min-width: 1400px) { .col-vl { flex-basis: 0; flex-grow: 1; max-width: 100%; }
  .row-cols-vl-1 > * { flex: 0 0 100%; max-width: 100%; }
  .row-cols-vl-2 > * { flex: 0 0 50%; max-width: 50%; }
  .row-cols-vl-3 > * { flex: 0 0 33.33333%; max-width: 33.33333%; }
  .row-cols-vl-4 > * { flex: 0 0 25%; max-width: 25%; }
  .row-cols-vl-5 > * { flex: 0 0 20%; max-width: 20%; }
  .row-cols-vl-6 > * { flex: 0 0 16.66667%; max-width: 16.66667%; }
  .col-vl-auto { flex: 0 0 auto; width: auto; max-width: 100%; }
  .col-vl-1 { flex: 0 0 8.33333%; max-width: 8.33333%; }
  .col-vl-2 { flex: 0 0 16.66667%; max-width: 16.66667%; }
  .col-vl-3 { flex: 0 0 25%; max-width: 25%; }
  .col-vl-4 { flex: 0 0 33.33333%; max-width: 33.33333%; }
  .col-vl-5 { flex: 0 0 41.66667%; max-width: 41.66667%; }
  .col-vl-6 { flex: 0 0 50%; max-width: 50%; }
  .col-vl-7 { flex: 0 0 58.33333%; max-width: 58.33333%; }
  .col-vl-8 { flex: 0 0 66.66667%; max-width: 66.66667%; }
  .col-vl-9 { flex: 0 0 75%; max-width: 75%; }
  .col-vl-10 { flex: 0 0 83.33333%; max-width: 83.33333%; }
  .col-vl-11 { flex: 0 0 91.66667%; max-width: 91.66667%; }
  .col-vl-12 { flex: 0 0 100%; max-width: 100%; }
  .order-vl-first { order: -1; }
  .order-vl-last { order: 13; }
  .order-vl-0 { order: 0; }
  .order-vl-1 { order: 1; }
  .order-vl-2 { order: 2; }
  .order-vl-3 { order: 3; }
  .order-vl-4 { order: 4; }
  .order-vl-5 { order: 5; }
  .order-vl-6 { order: 6; }
  .order-vl-7 { order: 7; }
  .order-vl-8 { order: 8; }
  .order-vl-9 { order: 9; }
  .order-vl-10 { order: 10; }
  .order-vl-11 { order: 11; }
  .order-vl-12 { order: 12; }
  .offset-vl-0 { margin-left: 0; }
  .offset-vl-1 { margin-left: 8.33333%; }
  .offset-vl-2 { margin-left: 16.66667%; }
  .offset-vl-3 { margin-left: 25%; }
  .offset-vl-4 { margin-left: 33.33333%; }
  .offset-vl-5 { margin-left: 41.66667%; }
  .offset-vl-6 { margin-left: 50%; }
  .offset-vl-7 { margin-left: 58.33333%; }
  .offset-vl-8 { margin-left: 66.66667%; }
  .offset-vl-9 { margin-left: 75%; }
  .offset-vl-10 { margin-left: 83.33333%; }
  .offset-vl-11 { margin-left: 91.66667%; } }

@media (min-width: 1600px) { .col-sl { flex-basis: 0; flex-grow: 1; max-width: 100%; }
  .row-cols-sl-1 > * { flex: 0 0 100%; max-width: 100%; }
  .row-cols-sl-2 > * { flex: 0 0 50%; max-width: 50%; }
  .row-cols-sl-3 > * { flex: 0 0 33.33333%; max-width: 33.33333%; }
  .row-cols-sl-4 > * { flex: 0 0 25%; max-width: 25%; }
  .row-cols-sl-5 > * { flex: 0 0 20%; max-width: 20%; }
  .row-cols-sl-6 > * { flex: 0 0 16.66667%; max-width: 16.66667%; }
  .col-sl-auto { flex: 0 0 auto; width: auto; max-width: 100%; }
  .col-sl-1 { flex: 0 0 8.33333%; max-width: 8.33333%; }
  .col-sl-2 { flex: 0 0 16.66667%; max-width: 16.66667%; }
  .col-sl-3 { flex: 0 0 25%; max-width: 25%; }
  .col-sl-4 { flex: 0 0 33.33333%; max-width: 33.33333%; }
  .col-sl-5 { flex: 0 0 41.66667%; max-width: 41.66667%; }
  .col-sl-6 { flex: 0 0 50%; max-width: 50%; }
  .col-sl-7 { flex: 0 0 58.33333%; max-width: 58.33333%; }
  .col-sl-8 { flex: 0 0 66.66667%; max-width: 66.66667%; }
  .col-sl-9 { flex: 0 0 75%; max-width: 75%; }
  .col-sl-10 { flex: 0 0 83.33333%; max-width: 83.33333%; }
  .col-sl-11 { flex: 0 0 91.66667%; max-width: 91.66667%; }
  .col-sl-12 { flex: 0 0 100%; max-width: 100%; }
  .order-sl-first { order: -1; }
  .order-sl-last { order: 13; }
  .order-sl-0 { order: 0; }
  .order-sl-1 { order: 1; }
  .order-sl-2 { order: 2; }
  .order-sl-3 { order: 3; }
  .order-sl-4 { order: 4; }
  .order-sl-5 { order: 5; }
  .order-sl-6 { order: 6; }
  .order-sl-7 { order: 7; }
  .order-sl-8 { order: 8; }
  .order-sl-9 { order: 9; }
  .order-sl-10 { order: 10; }
  .order-sl-11 { order: 11; }
  .order-sl-12 { order: 12; }
  .offset-sl-0 { margin-left: 0; }
  .offset-sl-1 { margin-left: 8.33333%; }
  .offset-sl-2 { margin-left: 16.66667%; }
  .offset-sl-3 { margin-left: 25%; }
  .offset-sl-4 { margin-left: 33.33333%; }
  .offset-sl-5 { margin-left: 41.66667%; }
  .offset-sl-6 { margin-left: 50%; }
  .offset-sl-7 { margin-left: 58.33333%; }
  .offset-sl-8 { margin-left: 66.66667%; }
  .offset-sl-9 { margin-left: 75%; }
  .offset-sl-10 { margin-left: 83.33333%; }
  .offset-sl-11 { margin-left: 91.66667%; } }

.ss-main { position: relative; display: inline-block; user-select: none; color: #666666; width: 100%; }

.ss-main .ss-single-selected { display: flex; cursor: pointer; width: 100%; height: 30px; padding: 6px; border: 1px solid #dee2e6; border-radius: 0.25rem; background-color: #fff; outline: 0; box-sizing: border-box; transition: background-color .2s; }

.ss-main .ss-single-selected.ss-disabled { background-color: #dee2e6; cursor: not-allowed; }

.ss-main .ss-single-selected.ss-open-above { border-top-left-radius: 0px; border-top-right-radius: 0px; }

.ss-main .ss-single-selected.ss-open-below { border-bottom-left-radius: 0px; border-bottom-right-radius: 0px; }

.ss-main .ss-single-selected .placeholder { display: flex; flex: 1 1 100%; align-items: center; overflow: hidden; text-overflow: ellipsis; white-space: nowrap; text-align: left; width: calc(100% - 30px); line-height: 1em; -webkit-user-select: none; -moz-user-select: none; -ms-user-select: none; user-select: none; }

.ss-main .ss-single-selected .placeholder * { display: flex; align-items: center; overflow: hidden; text-overflow: ellipsis; white-space: nowrap; width: auto; }

.ss-main .ss-single-selected .placeholder .ss-disabled { color: #dedede; }

.ss-main .ss-single-selected .ss-deselect { display: flex; align-items: center; justify-content: flex-end; flex: 0 1 auto; margin: 0 6px 0 6px; font-weight: bold; }

.ss-main .ss-single-selected .ss-deselect.ss-hide { display: none; }

.ss-main .ss-single-selected .ss-arrow { display: flex; align-items: center; justify-content: flex-end; flex: 0 1 auto; margin: 0 6px 0 6px; }

.ss-main .ss-single-selected .ss-arrow span { border: solid #666666; border-width: 0 2px 2px 0; display: inline-block; padding: 3px; transition: transform .2s, margin .2s; }

.ss-main .ss-single-selected .ss-arrow span.arrow-up { transform: rotate(-135deg); margin: 3px 0 0 0; }

.ss-main .ss-single-selected .ss-arrow span.arrow-down { transform: rotate(45deg); margin: -3px 0 0 0; }

.ss-main .ss-multi-selected { display: flex; flex-direction: row; cursor: pointer; min-height: 30px; width: 100%; padding: 0 0 0 3px; border: 1px solid #dee2e6; border-radius: 0.25rem; background-color: #fff; outline: 0; box-sizing: border-box; transition: background-color .2s; }

.ss-main .ss-multi-selected.ss-disabled { background-color: #dee2e6; cursor: not-allowed; }

.ss-main .ss-multi-selected.ss-disabled .ss-values .ss-disabled { color: #666666; }

.ss-main .ss-multi-selected.ss-disabled .ss-values .ss-value .ss-value-delete { cursor: not-allowed; }

.ss-main .ss-multi-selected.ss-open-above { border-top-left-radius: 0px; border-top-right-radius: 0px; }

.ss-main .ss-multi-selected.ss-open-below { border-bottom-left-radius: 0px; border-bottom-right-radius: 0px; }

.ss-main .ss-multi-selected .ss-values { display: flex; flex-wrap: wrap; justify-content: flex-start; flex: 1 1 100%; width: calc(100% - 30px); }

.ss-main .ss-multi-selected .ss-values .ss-disabled { display: flex; padding: 4px 5px; margin: 2px 0px; line-height: 1em; align-items: center; width: 100%; color: #dedede; overflow: hidden; text-overflow: ellipsis; white-space: nowrap; }

@keyframes scaleIn { 0% { transform: scale(0);
    opacity: 0; }
  100% { transform: scale(1);
    opacity: 1; } }

@keyframes scaleOut { 0% { transform: scale(1);
    opacity: 1; }
  100% { transform: scale(0);
    opacity: 0; } }

.ss-main .ss-multi-selected .ss-values .ss-value { display: flex; user-select: none; align-items: center; font-size: 12px; padding: 3px 5px; margin: 3px 5px 3px 0px; color: #fff; background-color: #5897fb; border-radius: 0.25rem; animation-name: scaleIn; animation-duration: .2s; animation-timing-function: ease-out; animation-fill-mode: both; }

.ss-main .ss-multi-selected .ss-values .ss-value.ss-out { animation-name: scaleOut; animation-duration: .2s; animation-timing-function: ease-out; }

.ss-main .ss-multi-selected .ss-values .ss-value .ss-value-delete { margin: 0 0 0 5px; cursor: pointer; }

.ss-main .ss-multi-selected .ss-add { display: flex; flex: 0 1 3px; margin: 9px 12px 0 5px; }

.ss-main .ss-multi-selected .ss-add .ss-plus { display: flex; justify-content: center; align-items: center; background: #666666; position: relative; height: 10px; width: 2px; transition: transform .2s; }

.ss-main .ss-multi-selected .ss-add .ss-plus:after { background: #666666; content: ""; position: absolute; height: 2px; width: 10px; left: -4px; top: 4px; }

.ss-main .ss-multi-selected .ss-add .ss-plus.ss-cross { transform: rotate(45deg); }

.ss-content { position: absolute; width: 100%; margin: -1px 0 0 0; box-sizing: border-box; border: solid 1px #dee2e6; z-index: 1010; background-color: #fff; transform-origin: center top; transition: transform .2s, opacity .2s; opacity: 0; transform: scaleY(0); }

.ss-content.ss-open { display: block; opacity: 1; transform: scaleY(1); }

.ss-content .ss-search { display: flex; flex-direction: row; padding: 8px 8px 6px 8px; }

.ss-content .ss-search.ss-hide { height: 0px; opacity: 0; padding: 0px 0px 0px 0px; margin: 0px 0px 0px 0px; }

.ss-content .ss-search.ss-hide input { height: 0px; opacity: 0; padding: 0px 0px 0px 0px; margin: 0px 0px 0px 0px; }

.ss-content .ss-search input { display: inline-flex; font-size: inherit; line-height: inherit; flex: 1 1 auto; width: 100%; min-width: 0px; height: 30px; padding: 6px 8px; margin: 0; border: 1px solid #dee2e6; border-radius: 0.25rem; background-color: #fff; outline: 0; text-align: left; box-sizing: border-box; -webkit-box-sizing: border-box; -webkit-appearance: textfield; }

.ss-content .ss-search input::placeholder { color: #8a8a8a; vertical-align: middle; }

.ss-content .ss-search input:focus { box-shadow: 0 0 5px #5897fb; }

.ss-content .ss-search .ss-addable { display: inline-flex; justify-content: center; align-items: center; cursor: pointer; font-size: 22px; font-weight: bold; flex: 0 0 30px; height: 30px; margin: 0 0 0 8px; border: 1px solid #dee2e6; border-radius: 0.25rem; box-sizing: border-box; }

.ss-content .ss-addable { padding-top: 0px; }

.ss-content .ss-list { max-height: 200px; overflow-x: hidden; overflow-y: auto; text-align: left; }

.ss-content .ss-list .ss-optgroup .ss-optgroup-label { padding: 6px 10px 6px 10px; font-weight: bold; }

.ss-content .ss-list .ss-optgroup .ss-option { padding: 6px 6px 6px 25px; }

.ss-content .ss-list .ss-optgroup-label-selectable { cursor: pointer; }

.ss-content .ss-list .ss-optgroup-label-selectable:hover { color: #fff; background-color: #5897fb; }

.ss-content .ss-list .ss-option { padding: 6px 10px 6px 10px; cursor: pointer; user-select: none; }

.ss-content .ss-list .ss-option * { display: inline-block; }

.ss-content .ss-list .ss-option:hover, .ss-content .ss-list .ss-option.ss-highlighted { color: #fff; background-color: #5897fb; }

.ss-content .ss-list .ss-option.ss-disabled { cursor: not-allowed; color: #dedede; background-color: #fff; }

.ss-content .ss-list .ss-option:not(.ss-disabled).ss-option-selected { color: #666666; background-color: rgba(88, 151, 251, 0.1); }

.ss-content .ss-list .ss-option.ss-hide { display: none; }

.ss-content .ss-list .ss-option .ss-search-highlight { background-color: #fffb8c; }

.datepicker { display: none; }

.datepicker.active { display: block; }

.datepicker-dropdown { position: absolute; top: 0; left: 0; z-index: 320; padding-top: 4px; }

.datepicker-dropdown.datepicker-orient-top { padding-top: 0; padding-bottom: 4px; }

.datepicker-picker { display: inline-block; border-radius: 4px; background-color: white; padding: 15px; }

.datepicker-dropdown .datepicker-picker { box-shadow: 0 2px 3px rgba(10, 10, 10, 0.1), 0 0 0 1px rgba(10, 10, 10, 0.1); }

.datepicker-picker span { display: block; flex: 1; border: 0; border-radius: 50%; cursor: default; text-align: center; -webkit-touch-callout: none; -webkit-user-select: none; -moz-user-select: none; -ms-user-select: none; user-select: none; }

.datepicker-main { padding: 2px; }

.datepicker-controls, .datepicker-view, .datepicker-view .days-of-week, .datepicker-grid { display: flex; }

.datepicker-grid { flex-wrap: wrap; }

.datepicker-view .dow, .datepicker-view .days .datepicker-cell { flex-basis: 14.28571%; font-size: 0.875rem; }

.datepicker-view.datepicker-grid .datepicker-cell { flex-basis: 25%; }

.datepicker-view .week, .datepicker-cell { height: 2.25rem; line-height: 2.25rem; }

.datepicker-title { box-shadow: inset 0 -1px 1px rgba(10, 10, 10, 0.1); background-color: whitesmoke; padding: 0.375rem 0.75rem; text-align: center; font-weight: 700; }

.datepicker-header .datepicker-controls { padding: 2px 2px 0; }

.datepicker-controls .button { display: inline-flex; position: relative; align-items: center; justify-content: center; margin: 0; border: 1px solid #dbdbdb; border-radius: 4px; box-shadow: none; background-color: white; cursor: pointer; padding: calc(0.375em - 1px) 0.75em; height: 2.25em; vertical-align: top; text-align: center; line-height: 1.5; white-space: nowrap; color: #101010; font-size: 1rem; }

.datepicker-controls .button:focus, .datepicker-controls .button:active { outline: none; }

.datepicker-controls .button:hover { border-color: #b5b5b5; color: #363636; }

.datepicker-controls .button:focus { border-color: #3273dc; color: #363636; }

.datepicker-controls .button:focus:not(:active) { box-shadow: 0 0 0 0.125em rgba(50, 115, 220, 0.25); }

.datepicker-controls .button:active { border-color: #4a4a4a; color: #363636; }

.datepicker-controls .button[disabled] { cursor: not-allowed; }

.datepicker-header .datepicker-controls .button { border-color: transparent; font-size: 0.875rem; text-transform: uppercase; }

.datepicker-header .datepicker-controls .button:hover { color: #4B5DFF; background-color: transparent; }

.datepicker-header .datepicker-controls .button:focus:not(:active) { box-shadow: 0 0 0 0.125em rgba(255, 255, 255, 0.25); }

.datepicker-header .datepicker-controls .button:active { background-color: #f2f2f2; }

.datepicker-header .datepicker-controls .button[disabled] { box-shadow: none; }

.datepicker-footer .datepicker-controls .button { margin-top: 10px; border-radius: 6px; border: none; width: 100%; color: #fff; background-color: #575757; font-size: 0.875rem; transition: .3s; }

.datepicker-footer .datepicker-controls .button:hover { background-color: #444444; }

.datepicker-controls .view-switch { flex: auto; }

.datepicker-controls .prev-btn, .datepicker-controls .next-btn { color: #4B5DFF; padding-right: 0.375rem; padding-left: 0.375rem; width: 2.25rem; }

.datepicker-controls .prev-btn svg, .datepicker-controls .next-btn svg { width: 0.7rem; fill: #4B5DFF; }

.datepicker-controls .prev-btn.disabled, .datepicker-controls .next-btn.disabled { visibility: hidden; }

.datepicker-view .dow { height: 1.5rem; line-height: 1.5rem; font-size: 0.875rem; color: rgba(16, 16, 16, 0.4); }

.datepicker-view .week { width: 2.25rem; color: #b5b5b5; font-size: 0.75rem; }

@media (max-width: 22.5rem) { .datepicker-view .week { width: 1.96875rem; } }

.datepicker-grid { width: 15.75rem; }

@media (max-width: 22.5rem) { .calendar-weeks + .days .datepicker-grid { width: 13.78125rem; } }

.datepicker-cell:not(.disabled):hover { background-color: rgba(75, 93, 255, 0.1); cursor: pointer; }

.datepicker-cell.month:not(.disabled):hover, .datepicker-cell.year:not(.disabled):hover { background: none; color: #4B5DFF; }

.datepicker-cell.focused:not(.selected) { background-color: #e8e8e8; }

.datepicker-cell.month.focused:not(.selected), .datepicker-cell.year.focused:not(.selected) { background: none; }

.datepicker-cell.selected, .datepicker-cell.selected:hover { background-color: #4B5DFF; color: #fff; }

.datepicker-cell.month.selected, .datepicker-cell.month.selected:hover, .datepicker-cell.year.selected, .datepicker-cell.year.selected:hover { border-radius: 0; background: none; color: #4B5DFF; font-family: "OpenSansSemiBold", sans-serif; }

.datepicker-cell.disabled { color: #dbdbdb; }

.datepicker-cell.prev:not(.disabled), .datepicker-cell.next:not(.disabled) { color: #7a7a7a; }

.datepicker-cell.highlighted:not(.selected):not(.range):not(.today) { border-radius: 0; background-color: whitesmoke; }

.datepicker-cell.highlighted:not(.selected):not(.range):not(.today):not(.disabled):hover { background-color: #eeeeee; }

.datepicker-cell.highlighted:not(.selected):not(.range):not(.today).focused { background-color: #e8e8e8; }

.datepicker-cell.today:not(.selected) { background-color: #35C402; }

.datepicker-cell.today:not(.selected):not(.disabled) { color: #fff; }

.datepicker-cell.today.focused:not(.selected) { background-color: #00c4a7; }

.datepicker-cell.range-start:not(.selected), .datepicker-cell.range-end:not(.selected) { background-color: rgba(75, 93, 255, 0.5); color: #fff; }

.datepicker-cell.range-start.focused:not(.selected), .datepicker-cell.range-end.focused:not(.selected) { background-color: #afafaf; }

.datepicker-cell.range-start { border-radius: 4px 0 0 4px; }

.datepicker-cell.range-end { border-radius: 0 4px 4px 0; }

.datepicker-cell.range { border-radius: 0; background-color: rgba(75, 93, 255, 0.1); }

.datepicker-cell.range:not(.disabled):not(.focused):not(.today):hover { background-color: #d5d5d5; }

.datepicker-cell.range.disabled { color: #c2c2c2; }

.datepicker-cell.range.focused { background-color: #cfcfcf; }

.datepicker-view.datepicker-grid .datepicker-cell { height: 4.5rem; line-height: 4.5rem; }

.datepicker-input.in-edit { border-color: #2366d1; }

[data-simplebar] { position: relative; flex-direction: column; flex-wrap: wrap; justify-content: flex-start; align-content: flex-start; align-items: flex-start; }

.simplebar-wrapper { overflow: hidden; width: inherit; height: inherit; max-width: inherit; max-height: inherit; }

.simplebar-mask { direction: inherit; position: absolute; overflow: hidden; padding: 0; margin: 0; left: 0; top: 0; bottom: 0; right: 0; width: auto !important; height: auto !important; z-index: 0; }

.simplebar-offset { direction: inherit !important; box-sizing: inherit !important; resize: none !important; position: absolute; top: 0; left: 0; bottom: 0; right: 0; padding: 0; margin: 0; -webkit-overflow-scrolling: touch; }

.simplebar-content-wrapper { direction: inherit; box-sizing: border-box !important; position: relative; display: block; height: 100%; /* Required for horizontal native scrollbar to not appear if parent is taller than natural height */ width: auto; max-width: 100%; /* Not required for horizontal scroll to trigger */ max-height: 100%; /* Needed for vertical scroll to trigger */ scrollbar-width: none; -ms-overflow-style: none; }

.simplebar-content-wrapper::-webkit-scrollbar, .simplebar-hide-scrollbar::-webkit-scrollbar { width: 0; height: 0; }

.simplebar-content:before, .simplebar-content:after { content: ' '; display: table; }

.simplebar-placeholder { max-height: 100%; max-width: 100%; width: 100%; pointer-events: none; }

.simplebar-height-auto-observer-wrapper { box-sizing: inherit !important; height: 100%; width: 100%; max-width: 1px; position: relative; float: left; max-height: 1px; overflow: hidden; z-index: -1; padding: 0; margin: 0; pointer-events: none; flex-grow: inherit; flex-shrink: 0; flex-basis: 0; }

.simplebar-height-auto-observer { box-sizing: inherit; display: block; opacity: 0; position: absolute; top: 0; left: 0; height: 1000%; width: 1000%; min-height: 1px; min-width: 1px; overflow: hidden; pointer-events: none; z-index: -1; }

.simplebar-track { z-index: 1; position: absolute; right: 0; bottom: 0; pointer-events: none; overflow: hidden; }

[data-simplebar].simplebar-dragging .simplebar-content { pointer-events: none; user-select: none; -webkit-user-select: none; }

[data-simplebar].simplebar-dragging .simplebar-track { pointer-events: all; }

.simplebar-scrollbar { position: absolute; left: 0; right: 0; min-height: 10px; }

.simplebar-scrollbar:before { position: absolute; content: ''; background: black; border-radius: 7px; left: 2px; right: 2px; opacity: 0; transition: opacity 0.2s linear; }

.simplebar-scrollbar.simplebar-visible:before { /* When hovered, remove all transitions from drag handle */ opacity: 0.5; transition: opacity 0s linear; }

.simplebar-track.simplebar-vertical { top: 0; width: 11px; }

.simplebar-track.simplebar-vertical .simplebar-scrollbar:before { top: 2px; bottom: 2px; }

.simplebar-track.simplebar-horizontal { left: 0; height: 11px; }

.simplebar-track.simplebar-horizontal .simplebar-scrollbar:before { height: 100%; left: 2px; right: 2px; }

.simplebar-track.simplebar-horizontal .simplebar-scrollbar { right: auto; left: 0; top: 2px; height: 7px; min-height: 0; min-width: 10px; width: auto; }

/* Rtl support */
[data-simplebar-direction='rtl'] .simplebar-track.simplebar-vertical { right: auto; left: 0; }

.hs-dummy-scrollbar-size { direction: rtl; position: fixed; opacity: 0; visibility: hidden; height: 500px; width: 500px; overflow-y: hidden; overflow-x: scroll; }

.simplebar-hide-scrollbar { position: fixed; left: 0; visibility: hidden; overflow-y: scroll; scrollbar-width: none; -ms-overflow-style: none; }
