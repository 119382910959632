$selectColor: #4B5DFF;


.datepicker {
    display: none;
}

.datepicker.active {
    display: block;
}

.datepicker-dropdown {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 320;
    padding-top: 4px;
}

.datepicker-dropdown.datepicker-orient-top {
    padding-top: 0;
    padding-bottom: 4px;
}

.datepicker-picker {
    display: inline-block;
    border-radius: 4px;
    background-color: white;
    padding: 15px;
}

.datepicker-dropdown .datepicker-picker {
    box-shadow: 0 2px 3px rgba(10, 10, 10, 0.1), 0 0 0 1px rgba(10, 10, 10, 0.1);
}

.datepicker-picker span {
    display: block;
    flex: 1;
    border: 0;
    border-radius: 50%;
    cursor: default;
    text-align: center;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
}

.datepicker-main {
    padding: 2px;
}

.datepicker-footer {
    //box-shadow: inset 0 1px 1px rgba(10, 10, 10, 0.1);
    //background-color: whitesmoke;
}

.datepicker-controls, .datepicker-view, .datepicker-view .days-of-week, .datepicker-grid {
    display: flex;
}

.datepicker-grid {
    flex-wrap: wrap;
}

.datepicker-view .dow, .datepicker-view .days .datepicker-cell {
    flex-basis: 14.28571%;
    font-size: 0.875rem;
}

.datepicker-view.datepicker-grid .datepicker-cell {
    flex-basis: 25%;
}

.datepicker-view .week, .datepicker-cell {
    height: 2.25rem;
    line-height: 2.25rem;
}

.datepicker-title {
    box-shadow: inset 0 -1px 1px rgba(10, 10, 10, 0.1);
    background-color: whitesmoke;
    padding: 0.375rem 0.75rem;
    text-align: center;
    font-weight: 700;
}

.datepicker-header .datepicker-controls {
    padding: 2px 2px 0;
}

.datepicker-controls .button {
    display: inline-flex;
    position: relative;
    align-items: center;
    justify-content: center;
    margin: 0;
    border: 1px solid #dbdbdb;
    border-radius: 4px;
    box-shadow: none;
    background-color: white;
    cursor: pointer;
    padding: calc(0.375em - 1px) 0.75em;
    height: 2.25em;
    vertical-align: top;
    text-align: center;
    line-height: 1.5;
    white-space: nowrap;
    color: #101010;
    font-size: 1rem;
}

.datepicker-controls .button:focus, .datepicker-controls .button:active {
    outline: none;
}

.datepicker-controls .button:hover {
    border-color: #b5b5b5;
    color: #363636;
}

.datepicker-controls .button:focus {
    border-color: #3273dc;
    color: #363636;
}

.datepicker-controls .button:focus:not(:active) {
    box-shadow: 0 0 0 0.125em rgba(50, 115, 220, 0.25);
}

.datepicker-controls .button:active {
    border-color: #4a4a4a;
    color: #363636;
}

.datepicker-controls .button[disabled] {
    cursor: not-allowed;
}

.datepicker-header .datepicker-controls .button {
    border-color: transparent;
    font-size: 0.875rem;
    text-transform: uppercase;
    //font-weight: bold;
}

.datepicker-header .datepicker-controls .button:hover {
    color: $selectColor;
    background-color: transparent;
}

.datepicker-header .datepicker-controls .button:focus:not(:active) {
    box-shadow: 0 0 0 0.125em rgba(255, 255, 255, 0.25);
}

.datepicker-header .datepicker-controls .button:active {
    background-color: #f2f2f2;
}

.datepicker-header .datepicker-controls .button[disabled] {
    box-shadow: none;
}

.datepicker-footer .datepicker-controls .button {
    //margin: calc(0.375rem - 1px) 0.375rem;
    margin-top: 10px;
    border-radius: 6px;
    border: none;
    width: 100%;
    color: #fff;
    background-color: #575757;
    font-size: 0.875rem;
    transition: .3s;
}

.datepicker-footer .datepicker-controls .button:hover {
    background-color: #444444;
}

.datepicker-controls .view-switch {
    flex: auto;
}

.datepicker-controls .prev-btn,
.datepicker-controls .next-btn {
    color: $selectColor;
    padding-right: 0.375rem;
    padding-left: 0.375rem;
    width: 2.25rem;
}
.datepicker-controls .prev-btn svg,
.datepicker-controls .next-btn svg {
  width: 0.7rem;
  fill: $selectColor;
}

.datepicker-controls .prev-btn.disabled,
.datepicker-controls .next-btn.disabled {
    visibility: hidden;
}

.datepicker-view .dow {
    height: 1.5rem;
    line-height: 1.5rem;
    font-size: 0.875rem;
    color: rgba(#101010, 0.4);
    //font-weight: 700;
}

.datepicker-view .week {
    width: 2.25rem;
    color: #b5b5b5;
    font-size: 0.75rem;
}

@media (max-width: 22.5rem) {
    .datepicker-view .week {
        width: 1.96875rem;
    }
}

.datepicker-grid {
    width: 15.75rem;
}

@media (max-width: 22.5rem) {
    .calendar-weeks + .days .datepicker-grid {
        width: 13.78125rem;
    }
}

.datepicker-cell:not(.disabled):hover {
    background-color: rgba($selectColor, 0.1);
    cursor: pointer;
}

.datepicker-cell.month:not(.disabled):hover,
.datepicker-cell.year:not(.disabled):hover {
    background: none;
    color: $selectColor;
}

.datepicker-cell.focused:not(.selected) {
    background-color: #e8e8e8;
}

.datepicker-cell.month.focused:not(.selected), .datepicker-cell.year.focused:not(.selected)  {
    background: none;
}

.datepicker-cell.selected, .datepicker-cell.selected:hover {
    background-color: $selectColor;
    color: #fff;
    //font-weight: 600;
}

.datepicker-cell.month.selected, .datepicker-cell.month.selected:hover,
.datepicker-cell.year.selected, .datepicker-cell.year.selected:hover, {
  border-radius: 0;
  background: none;
  color: $selectColor;
  font-family: "OpenSansSemiBold", sans-serif;
}

.datepicker-cell.disabled {
    color: #dbdbdb;
}

.datepicker-cell.prev:not(.disabled), .datepicker-cell.next:not(.disabled) {
    color: #7a7a7a;
}

.datepicker-cell.highlighted:not(.selected):not(.range):not(.today) {
    border-radius: 0;
    background-color: whitesmoke;
}

.datepicker-cell.highlighted:not(.selected):not(.range):not(.today):not(.disabled):hover {
    background-color: #eeeeee;
}

.datepicker-cell.highlighted:not(.selected):not(.range):not(.today).focused {
    background-color: #e8e8e8;
}

.datepicker-cell.today:not(.selected) {
    //background-color: #00d1b2;
    background-color: #35C402;
}

.datepicker-cell.today:not(.selected):not(.disabled) {
    color: #fff;
}

.datepicker-cell.today.focused:not(.selected) {
    background-color: #00c4a7;
}

.datepicker-cell.range-start:not(.selected), .datepicker-cell.range-end:not(.selected) {
    background-color: rgba($selectColor, 0.5);
    color: #fff;
}

.datepicker-cell.range-start.focused:not(.selected), .datepicker-cell.range-end.focused:not(.selected) {
    background-color: #afafaf;
}

.datepicker-cell.range-start {
    border-radius: 4px 0 0 4px;
}

.datepicker-cell.range-end {
    border-radius: 0 4px 4px 0;
}

.datepicker-cell.range {
    border-radius: 0;
    background-color: rgba($selectColor, 0.1);
}

.datepicker-cell.range:not(.disabled):not(.focused):not(.today):hover {
    background-color: #d5d5d5;
}

.datepicker-cell.range.disabled {
    color: #c2c2c2;
}

.datepicker-cell.range.focused {
    background-color: #cfcfcf;
}

.datepicker-view.datepicker-grid .datepicker-cell {
    height: 4.5rem;
    line-height: 4.5rem;
}

.datepicker-input.in-edit {
    border-color: #2366d1;
}

.datepicker-input.in-edit:focus, .datepicker-input.in-edit:active {
    //box-shadow: 0 0 0.25em 0.25em rgba(35, 102, 209, 0.2);
}
