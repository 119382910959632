@import 'mixins';

@include font-face('OpenSansRegular', '../fonts/OpenSans/OpenSansRegular/OpenSansRegular');
@include font-face('OpenSansLight', '../fonts/OpenSans/OpenSansLight/OpenSansLight');
@include font-face('OpenSansSemiBold', '../fonts/OpenSans/OpenSansSemiBold/OpenSansSemiBold');
@include font-face('OpenSansBold', '../fonts/OpenSans/OpenSansBold/OpenSansBold');
@include font-face('OpenSansItalic', '../fonts/OpenSans/OpenSansItalic/OpenSansItalic');


@include font-face('RubikRegular', '../fonts/Rubik/RubikRegular/RubikRegular');
@include font-face('RubikMedium', '../fonts/Rubik/RubikMedium/RubikMedium');
@include font-face('RubikLight', '../fonts/Rubik/RubikLight/RubikLight');